import React, { useState } from 'react';
import './css/patient-services.css'

export default function PatientServicesHistory({ patientInfos }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const serviceHistory = patientInfos.ServicesHistory || [];

  const repairCreditsMapping = {
    repair_min_credits: 'Nível 1',
    repair_med_credits: 'Nível 2',
    repair_max_credits: 'Nível 3',
    repair_sp_credits: 'Special',
    Nenhum: 'Nenhum'
  };

  const othersMapping = {
    cleaning_credits: 'Limpeza',
    Nenhum: 'Nenhum',
    obturation_credits: 'Restauração',
    extraction_credits: 'Extração',
    canal_credits: 'Trat. canal',
    crown_credits: 'Prótese',
    whitening_credits: 'Clareamento c.',
    laser_whit_credits: 'Clareamento l.'
  };
  
  // Filtrar histórico por datas
  const filteredHistory = serviceHistory.filter((service) => {
    if (!startDate && !endDate) return true;
    const serviceDate = new Date(service.date).getTime();
    const start = startDate ? new Date(startDate).getTime() : null;
    const end = endDate ? new Date(endDate).getTime() : null;

    return (!start || serviceDate >= start) && (!end || serviceDate <= end);
  });

  // Selecionar últimos 3 serviços ou todos se filtrado
  const displayedHistory =
    startDate || endDate ? filteredHistory : serviceHistory.slice(-3);

  return (
    <div className="patient-history-container">
      <div className='transa-title-div'>
      <h2>Histórico de Serviços</h2>
      </div>
      {/* Filtros de Data */}
      <div className="filter-container">
        <label>
          Data Inicial:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          Data Final:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
      </div>

      {/* Carrossel */}
      {displayedHistory.length > 0 ? (
        <div className="carousel-container-services">
          {displayedHistory.map((service, index) => (
            <div className="card" key={index}>
              <p>
                <strong>Data:</strong> {new Date(service.date).toLocaleDateString('pt-BR')}
              </p>
              <p>
                <strong>Manutenção:</strong> {service.maintence}
              </p>
              <p>
                <strong>Reparo:</strong> {repairCreditsMapping[service.repair]}
              </p>
              <p>
                <strong>Outros:</strong> {othersMapping[service.others]}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-services">Nenhum serviço realizado.</p>
      )}
    </div>
  );
}
