import { BASE_URL } from "./hostUrl";

// src/utils/checkBackend.js
export const checkBackend = async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/health-check`);
    if (response.ok) {
      return true;
    }
  } catch (error) {
    console.error('Backend não está acessível:', error);
  }
  return false;
};
