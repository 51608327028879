import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verifica se há um token salvo
    const token = localStorage.getItem('accessToken');
    if (token) {
      // Aqui você pode fazer uma chamada à API para validar o token e buscar os dados do usuário
      setUser({ token }); // Simplesmente adicionando o token ao estado por enquanto
    }
    setLoading(false);
  }, []);

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    setUser(undefined);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
