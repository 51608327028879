import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import api from '../../services/api';
import { BASE_URL } from '../../utils/hostUrl';
import './css/notice-board.css';

const NoticeBoard = ({ admOnly }) => {
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const socketRef = useRef(null); // Gerenciar conexão WebSocket
  const isFetching = useRef(false); // Evitar chamadas simultâneas

  // Função para buscar a mensagem inicial do backend
  const fetchNoticeBoard = async () => {
    if (isFetching.current) return; // Evitar múltiplas chamadas simultâneas
    isFetching.current = true;
    setLoading(true);
    setError('');
    try {
      const response = await api.get(`${BASE_URL}/notice-board/get`);
      setResponseMessage(response.data.content);
    } catch (err) {
      console.error('Erro ao buscar a mensagem:', err);
      setError('Erro ao carregar a mensagem.');
    } finally {
      setLoading(false);
      isFetching.current = false;
    }
  };

  // Atualizar mensagem no painel
  const updateMessage = async () => {
    if (!message.trim()) return; // Evitar mensagens vazias
    setLoading(true);
    setError('');
    try {
      await api.put(`${BASE_URL}/notice-board/update`, { content: message });
      socketRef.current.emit('updateNoticeBoard'); // Notificar via WebSocket
      setMessage('');
    } catch (err) {
      console.error('Erro ao atualizar a mensagem:', err);
      setError('Erro ao atualizar a mensagem.');
    } finally {
      setLoading(false);
    }
  };

  // Resetar mensagem para o padrão
  const resetMessage = async () => {
    setLoading(true);
    setError('');
    try {
      await api.delete(`${BASE_URL}/notice-board/reset`);
      socketRef.current.emit('updateNoticeBoard'); // Notificar via WebSocket
    } catch (err) {
      console.error('Erro ao resetar a mensagem:', err);
      setError('Erro ao resetar a mensagem.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Configurar conexão WebSocket apenas uma vez
    if (!socketRef.current) {
      socketRef.current = io(BASE_URL, { transports: ['websocket'] });

      // Listener para mensagens de atualização
      socketRef.current.on('updateNoticeBoard', () => {
        fetchNoticeBoard(); // Atualizar a mensagem ao receber notificação
      });
    }

    fetchNoticeBoard(); // Buscar mensagem inicial ao montar o componente

    // Cleanup na desmontagem
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []); // Dependências vazias para evitar loops

  return (
    <div className="notice-board">
      <h1 className="notice-title">Painel de Avisos</h1>
      <div className="notice-content">
        {loading ? <p>Carregando...</p> : <p>{responseMessage}</p>}
        {error && <p className="error">{error}</p>}
      </div>
      {admOnly && (
        <div className="notice-actions">
          <textarea
            className="notice-input"
            placeholder="Digite uma nova mensagem..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button className="notice-btn update-btn" onClick={updateMessage} disabled={loading}>
            Atualizar Mensagem
          </button>
          <button className="notice-btn reset-btn" onClick={resetMessage} disabled={loading}>
            Resetar para Padrão
          </button>
        </div>
      )}
    </div>
  );
};

export default NoticeBoard;
