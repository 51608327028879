import React, { useState } from 'react';
import './css/explanation-boxes.css';

export default function RepairBoxExp({ toggleExpand }) {
  const [stage, setStage] = useState(1); // Estado para controlar o estágio atual

  // Função para ir ao próximo estágio
  const nextStage = () => {
    if (stage < 3) setStage(stage + 1);
    if (stage === 2) toggleExpand();
  };

  // Função para voltar ao estágio anterior
  const prevStage = () => {
    if (stage > 1) setStage(stage - 1);
    if (stage === 3) toggleExpand();
  };

  // Renderização condicional com base no estágio
  return (
    <div className='box-explanation-main-container'>
      {stage === 1 && (
        <>
          <span className='box-exp-subtitle'>Soltou um braquete?<br></br>
            O fio está machucando?</span>
          <p className='box-exp-first-paragraf-repair'>
            <span>Com os créditos de reparo , você resolve seu problema !</span>
            <span>Não precisa mais aguardar até a próxima manutenção para realizar o conserto.
        </span>
          </p>
          <button onClick={nextStage} className='box-exp-button-repair'>Ver detalhes</button>
        </>
      )}

      {stage === 2 && (
        <>
          <span className='box-exp-subtitle'>São 4 tipos de reparo</span>
          <div className='advantages-main-container'>
            <ul className='advantages-sub-container'>
              <li className='box-exp-advantages-title'>&bull; Reparo Nível 1:</li>
              <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
                - Re-colagem de 1 a 3 braquetes;
              </span>
              <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
              - Corte ou ajuste do fio.
              </span>
            </ul>
            <div className='advantages-sub-container'>
              <span className='box-exp-advantages-title'>&bull; Reparo Nível 2:</span>
              <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
              - Re-colagem de 4 a 10 braquetes;
              </span>
              <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
              - Corte ou ajuste do fio.
              </span>
            </div>
                <div className='advantages-sub-container'>
                  <span className='box-exp-advantages-title'>&bull; Reparo Nível 3:</span> 
                  <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
               {' '} - Re-colagem Total;
              </span>
              <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
               {' '} - Corte ou ajuste do fio.
              </span>
              </div>
              <div className='advantages-sub-container'>
                  <span className='box-exp-advantages-title'>&bull; Reparo Special:</span> 
                  <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
               {' '} - Destinado para os aparelhos ESTÉTICOS OU AUTO-LIGADO.
              </span>
                  <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
               {' '} - Re-colagem 1 a 3 braquetes;
              </span>
              <span style={{width:"80vw"}} className='box-exp-advantages-repair'>
               {' '} - Corte ou ajuste do fio.
              </span>
              </div>
          </div>
          <div className='box-exp-btn-stagio2-container'>
          <button onClick={prevStage} className='box-exp-button-stagio2-voltar-repair'>Voltar</button>
          <button onClick={nextStage} className='box-exp-button-stagio2-valores-repair'>Ver valores</button>
          </div>
      
        </>
      )}

      {stage === 3 && (
        <>
       <span className='box-exp-first-paragraf-repair'>Com os créditos de reparo, <br></br>seu sorriso nunca fica na mão.</span>
          <button onClick={prevStage} className='box-exp-button-repair'>Voltar</button>
        </>
        
      )}
    </div>
  );
}
