import React from 'react'
import '../cadastro/css/registerbyPatCover.css'

export default function PatientProfileCover({patientName = ''}) {
  return (
    <div className='cover-main-ort'>
      <div className='welcome-pat-profile-container'>
        <h2 className='welcome-pat-profile'>Bem Vindo(a)! </h2>
        <span className="ortodontia-text-cover-name">{patientName} </span>
      </div>

      <div className='patient-cover-text-container'>
        <p className='patient-cover-text-p'>A</p>
        <span className="ortodontia-text-cover">
          ortodontia
        </span>
        <p className='patient-cover-text-p'>feita para você.</p>
      </div>
    
    </div>
  )
}
