import React, { useState } from "react";
import './css/patient-credits-wallet.css';

export default function PatientCreditsWallet({ patientInfos, patientUnlock }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  
  
  const wallets = [
    {
      title: "Manutenção",
      content: (
        <>
          <p>Standard: <span>{patientInfos.CreditsWallet[0].standard}</span></p>
          <p>Smart: <span>{patientInfos.CreditsWallet[0].smart}</span></p>
          <p>VIP: <span>{patientInfos.CreditsWallet[0].vip}</span></p>
          <p>Special: <span>{patientInfos.CreditsWallet[0].special}</span></p>
          <p>Instal. Sp: <span>{patientInfos.CreditsWallet[0].specialIn}</span></p>
        </>
      ),
    },
    {
      title: "Reparo",
      content: (
        <>
          <p>Nível 1: <span>{patientInfos.RepairCreditsWallet[0].repair_min_credits}</span></p>
          <p>Nível 2: <span>{patientInfos.RepairCreditsWallet[0].repair_med_credits}</span></p>
          <p>Nível 3: <span>{patientInfos.RepairCreditsWallet[0].repair_max_credits}</span></p>
          <p>Special: <span>{patientInfos.RepairCreditsWallet[0].repair_sp_credits}</span></p>
        </>
      ),
    },
    {
      title: "Benefícios",
      content: (
        <>
          <p>Limpeza : <span>{patientInfos.BenefitsWallet[0].cleaning_credits}</span></p>
          <p>Restauração : <span>{patientInfos.BenefitsWallet[0].obturation_credits}</span></p>
          <p>Extração : <span>{patientInfos.BenefitsWallet[0].extraction_credits}</span></p>
          <p>Trat. de Canal : <span>{patientInfos.BenefitsWallet[0].canal_credits}</span></p>
          <p>Desc. Prótese : <span>{patientInfos.BenefitsWallet[0].crown_credits}</span></p>
          <p>Clar. Caseiro : <span>{patientInfos.BenefitsWallet[0].whitening_credits}</span></p>
          {/* <p>Clar. Laser : <span>{patientInfos.BenefitsWallet[0].laser_whit_credits}</span></p> */}

        </>
      ),
    },
    {
      title: "Desbloquear Benefícios",
      content: (
        <>
        <div className="unlock-sub-title">
          <li >Pontos Faltantes</li>
          </div>
          <li className="carousel-item-unlock">Limpeza : <span>{patientUnlock[0].cleaning}</span></li>
          <li className="carousel-item-unlock">Restauração : <span>{patientUnlock[1].obturation}</span></li>
          <li className="carousel-item-unlock">Desc. Extração : <span>{patientUnlock[5].extraction}</span></li>
          <li className="carousel-item-unlock">Trat. de Canal : <span>{patientUnlock[2].canal}</span></li>
          <li className="carousel-item-unlock">Desc. Prótese : <span>{patientUnlock[4].crown}</span></li>
          <li className="carousel-item-unlock">Clar. Caseiro : <span>{patientUnlock[3].whitening} </span></li>
        </>
      ),
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? wallets.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === wallets.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="carousel-container">
      
      <div className="carousel">
        
      {/* <div className="carousel-buttons">
      </div> */}
        <button className="carousel-buttons-button-prev" onClick={handlePrev}>{"<"}</button>
        <button className="carousel-buttons-button-foward" onClick={handleNext}>{">"}</button>
        
        {wallets.map((wallet, index) => (
          <div
            key={index}
            className={`carousel-item ${
              index === currentIndex ? "active" : "inactive"
            }`}
          >
            <div className="patient-credits-title">
            <h4>{wallet.title}</h4>
            </div>
            {wallet.title === 'Desbloquear Benefícios' 
            ? <h3 className='unlock-infos'>O valor adquirido em créditos de manutenção será convertido em pontos para desbloquear créditos de benefícios.</h3> 
            : <h3 className="carousel-sub-title">Meus Créditos:</h3> }
            
            <div className="wallet-content">
              <div className="content-text-aling">
            {wallet.content}
            </div>
            </div>
            
          </div>
        ))}
      </div>

    </div>
  );
}
