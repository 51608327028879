import React from 'react';
import './css/rules.css'

export default function Rules() {
  return (
    <div className="rules-container">
      <h1 className="rules-title">Créditos</h1>
      <ul className="rules-list">
        <li>
          Os créditos de benefícios recebidos são bônus sem valor comercial, não podendo ser reembolsados, transferidos para terceiros e sendo de uso exclusivo do titular.
        </li>
        <li>
          A realização dos procedimentos adquiridos com créditos de benefícios junto às clínicas parceiras da Ortho Start está sujeita exclusivamente à disponibilidade da agenda das clínicas.
        </li>
        <li>
          O uso de créditos de benefício para qualquer procedimento em clínicas parceiras anula a possibilidade de reembolso dos créditos pela Ortho Start.
        </li>
        <li>
          A solicitação de reembolso implica na anulação de todos os créditos, incluindo manutenção, reparos e benefícios, reduzindo-os a zero.
        </li>
        <li>
          As clínicas parceiras não possuem jurisdição sobre a comercialização de créditos ou questões financeiras e jurídicas relacionadas, sendo esta responsabilidade exclusiva da Ortho Start.
        </li>
        <li>
          O uso de créditos está condicionado à apresentação de documentação de identidade necessária ou outro comprovante solicitado pela clínica parceira.
        </li>
        <li>
          A solicitação de reembolso poderá estar sujeita a custos administrativos, os quais serão previamente informados ao titular no momento da solicitação.
        </li>
        <li>
          O titular poderá desistir da aquisição de créditos no prazo de até 7 dias após a compra, conforme previsto no Código de Defesa do Consumidor, desde que nenhum dos créditos ainda não tenham sido utilizados.
        </li>
        <li>
          As condições de uso dos créditos de benefícios poderão ser atualizadas pela Ortho Start mediante aviso prévio de 3 dias aos titulares, por meio de canais oficiais.
        </li>
      </ul>

      <h2 className="rules-subtitle">Créditos de Benefício: Tratamento de Canal e Extração</h2>
      <ul className="rules-list">
        <li>
          O crédito de benefício para tratamento de canal integral ou extração não se aplica a dentes molares.
        </li>
        <li>
          O desconto para tratamento de canal ou extração de dentes molares varia de 30% ou mais, a critério da clínica parceira.
        </li>
        <li>
          Caso a clínica parceira não possua os recursos necessários para realizar o tratamento de canal ou extração, deve comprovar essa incapacidade.
        </li>
        <li>
          Se comprovada a impossibilidade de realizar o procedimento, o crédito de benefício pode ser convertido em outras opções de créditos de benefícios, conforme a seguir:
          <ul>
            <li>Tratamento de canal: 1 voucher para prótese unitária ou 2 créditos de benefício para extração/restauração, ou combinações equivalentes.</li>
            <li>Extração: 1 crédito de benefício para restauração ou 2 créditos de benefício para limpeza dentária.</li>
          </ul>
        </li>
        <li>
          A troca de créditos só é permitida mediante comprovação da clínica parceira sobre a impossibilidade de realizar o procedimento original.
        </li>
      </ul>
    </div>
  );
}
