import React from 'react'
import Header from '../../components/header/Header'
import ManualCreditsAddForm from '../../components/adm/ManualCreditsAddForm'

export default function AdministrationMaster() {
  return (
    <div>
      <Header />
      <ManualCreditsAddForm />
    </div>
  )
}
