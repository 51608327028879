import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import './css/patient-inservice.css'
import TreatmentSheets from './TreatmentSheets';
import birthDateToAge from '../../utils/birth-date-to-age';
import TreatmentForm from './TreatmentForm';
import { BASE_URL } from '../../utils/hostUrl';

const PatientInServiceInfos = ({ updateTrigger, isDentist, id }) => {
  const [allocations, setAllocations] = useState([]);
  const [patients, setPatients] = useState({});
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    tooth: '',
    region: '',
    description: '',
    obs: '',
  });
  const [error, setError] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [modalData, setModalData] = useState(null); // Estado para o modal

  

  useEffect(() => {
    const fetchAllocations = async () => {
      let response;
      try {
       isDentist ? response = await fetch(`${BASE_URL}/dentist-allocations/${id}`) :
        response = await fetch(`${BASE_URL}/dentist-allocations`);
        if (response.ok) {  
          const data = await response.json();    
          setAllocations(data); 
          const patientPromises = data.map(async (allocation) => {
            if (allocation.patient_id !== 0) {
              const patientResponse = await fetch(`${BASE_URL}/patients/idBody`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idBody: allocation.patient_id.toString() }),
              });

              if (patientResponse.ok) {
                const patientData = await patientResponse.json();
                return { [allocation.patient_id]: patientData };
              }
            }
            return { [allocation.patient_id]: null };
          });

          const patientsData = await Promise.all(patientPromises);
          const patientsMap = Object.assign({}, ...patientsData);
          setPatients(patientsMap);
        } else {
          throw new Error('Erro ao buscar alocações');
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllocations();

    const socket = io(`${BASE_URL}`, {
      transports: ['websocket'], // Apenas WebSocket
    });
  
    socket.on('updatePatientInserviceInfos', () => {
      
      setTimeout(() => {
        fetchAllocations(); // Recarregar os dados após 2 segundos
      }, 2000);
    });

    return () => {
      socket.disconnect(); // Desconectar o socket ao desmontar o componente
    };
  }, [updateTrigger, isDentist, id]);
    

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (patientId) => {
    setFormLoading(true);
    setError('');

    try {
      const response = await fetch(`${BASE_URL}/treatment-sheet/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          patientId,
          ...formData,
          tooth: formData.tooth ? parseInt(formData.tooth, 10) : undefined,
        }),
      });
      
      if (response.ok) {
        const newSheet = await response.json();
        setPatients((prev) => ({
          ...prev,
          [patientId]: {
            ...prev[patientId],
            TreatmentSheet: [...(prev[patientId].TreatmentSheet || []), newSheet],
          },
        }));
        setFormData({
          name: '',
          tooth: '',
          region: '',
          description: '',
          obs: '',
        });
      } else {
        throw new Error('Erro ao criar ficha de tratamento');
      }
    } catch (error) {
      setError('Erro ao salvar os dados. Tente novamente.');
    } finally {
      setFormLoading(false);
    }
  };

  const handleConcludeService = async (allocation, cancel = false) => {
    const { id, patient_id, dentist_id, category, others, repair, useTitularCred } = allocation;
    const serviceObj = { category, others, repair };
    try {
      const response = await fetch(`${BASE_URL}/quee/inservice-conclude`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          patientId: patient_id,
          dentistId: dentist_id,
          serviceObj,
          cancel,
          useTitularCred
        }),
      });
      if (response.ok) {
        await fetch(`${BASE_URL}/dentist-allocations/reset-p`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            patientId: patient_id,
          }),
        });
  
        alert('Serviço concluído com sucesso!');
  
        // Atualizar apenas a alocação específica
        setAllocations((prevAllocations) =>
          prevAllocations.map((alloc) =>
            alloc.id === id
              ? { ...alloc, patient_id: 0, category: null, others: null, repair: null }
              : alloc
          )
        );
      } else {
        throw new Error('Erro ao concluir o serviço.');
      }
    } catch (error) {
      console.error(error);
      alert('Erro ao concluir o serviço. Verifique os dados e tente novamente.');
    }
  };

    // Função para abrir o modal
    const openModal = (allocation, cancel = false) => {
      setModalData({ allocation, cancel });
    
      
    };
  
    // Fechar o modal
    const closeModal = () => {
      setModalData(null);
    };
  

  if (loading) {
    return <p>Carregando...</p>;
  }

  return (
    <div className='patient-inservice-main'>
                       {/* Modal */}
                       {modalData && (
        <div className="modal">
          <div className='modal-content'>
          <p>
            Tem certeza que deseja{' '}
            {modalData.cancel ? 'CANCELAR o serviço?' : 'CONCLUIR o serviço?'}
          </p>
          <div className='modal-btns-container'>
          <button
          className='modal-btn-confirm '
            onClick={() => {
              handleConcludeService(modalData.allocation, modalData.cancel);
              closeModal();
            }}
          >
            Sim
          </button>
          <button
          className='modal-btn-cancel'
           onClick={closeModal}>Não</button>
          </div>
          </div>
        </div>
      )}
      {allocations.map((allocation) => (
        <div className='consultorio-card'
        key={allocation.id} >
          <div className='consultorio-card-title'>
          <p>Consultório {allocation.room}</p>     
          <p>Dr(a): {allocation.dentist_name}</p>
          </div>
          {allocation.patient_id !== 0 && patients[allocation.patient_id] ? (
            <>
            <div className='consultorio-card-patient-age'>
              <p><strong>Paciente:</strong> {patients[allocation.patient_id].name || 'N/A'}</p>
              <p><strong>Idade:</strong> {birthDateToAge(patients[allocation.patient_id].age)  || 'N/A'}</p>
              </div>
              <div className='patient-services-infos'>
          <p><strong>Manutenção:</strong> {allocation.category || 'N/A'}</p>
          <p><strong>Serviço:</strong> {allocation.others || 'N/A'}</p>
          <p><strong>Reparo:</strong> {allocation.repair || 'N/A'}</p>
          </div>
  
    
        <div className='card-conclude-cancel-btns-container'>
              <button className='card-conclude-btn'
                onClick={() => openModal(allocation)}
    
              >
                Concluir Serviço
              </button>

              <button className='card-cancel-btn'
              onClick={() => openModal(allocation, true)}
  
            >
              Cancelar Serviço
            </button>

            </div>

              {patients[allocation.patient_id].TreatmentSheet?.length > 0 ? (
                // <div className='treatment-sheet-main-container'>
                //   <h4 className='fichas-de-tratamento'>Fichas de Tratamento:</h4>
                //   <ul className='ul-fichas'>
                //     {patients[allocation.patient_id].TreatmentSheet.map((sheet) => (
                //       <li className='li-fichas' key={sheet.id}>
                //         <strong>Nome:</strong> {sheet.name}  <strong>Dente:</strong> {sheet.tooth || 'N/A'}
                //         <strong>Região:</strong> {sheet.region || 'N/A'}
                //         <br />
                //         <strong>Descrição:</strong> {sheet.description}
                //         <br />
                //         <strong>Observação:</strong> {sheet.obs || 'Nenhuma'}
                //         <br />
                //         <strong>Criado em:</strong> {new Date(sheet.createdAt).toLocaleString('pt-BR')}
                //       </li>
                //     ))}
                //   </ul>
                // </div>
                <TreatmentSheets patients={patients} allocation={allocation}/>
              ) : (
                <p>Nenhuma ficha de tratamento encontrada.</p>
              )}

              <TreatmentForm handleSubmit={handleSubmit} handleChange={handleChange} 
              formData={formData} formLoading={formLoading} error={error}
               allocation={allocation}/>

              {/* <h4 className='form-treatment-sheet-h4'>Adicionar Nova Ficha</h4>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <form 
              
              className='form-treatment-sheet'
               onSubmit={(e) => { e.preventDefault(); handleSubmit(allocation.patient_id); }}>
                <label>
                  Nome do Tratamento
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Dente:
                  <input
                    type="number"
                    name="tooth"
                    value={formData.tooth}
                    onChange={handleChange}
                    min="0"
                    max="38"
                  />
                </label>
                <label>
                  Região:
                  <input
                    type="text"
                    name="region"
                    value={formData.region}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Descrição:
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Observação:
                  <textarea
                    name="obs"
                    value={formData.obs}
                    onChange={handleChange}
                  />
                </label>
                <button type="submit" disabled={formLoading}>
                  {formLoading ? 'Salvando...' : 'Adicionar'}
                </button>
              </form> */}
            </>
          ) : (
            <p>Nenhum paciente alocado.</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default PatientInServiceInfos;
