import React, { useState } from 'react';
import './css/productsCardMob.css';
import RepairInfoValues from './prodctsInfosValues/RepairInfoValues';

export default function RepairContainer({ repair,handleCheckout }) {
  // Estado que controla quais produtos estão expandidos
  const [expandedItems, setExpandedItems] = useState({});

  // Função para alternar o estado de um produto específico
  const toggleExpand = (item) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item] // Alterna o estado de exibição
    }));
  };

  return (
    <div className='products-card-sub-container'>
      <div className='product-infos-container-repair' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>NIVEL 1</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {repair.repair1},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('repair1')} style={{color:'#2e2e2e', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.repair1 && <RepairInfoValues productName="Repair 1" handleCheckout={handleCheckout} />}
        
      </div>

      <div className='product-infos-container-repair' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>NIVEL 2</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {repair.repair2},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('repair2')} style={{color:'#2e2e2e', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.repair2 && <RepairInfoValues productName="Repair 2"  handleCheckout={handleCheckout}/>}
      </div>

      <div className='product-infos-container-repair' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>NIVEL 3</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {repair.repair3},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('repair3')} style={{color:'#2e2e2e', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.repair3 && <RepairInfoValues productName="Repair 3"  handleCheckout={handleCheckout}/>}
      </div>

      <div className='product-infos-container-repair' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>SPECIAL</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {repair.repairSp},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('repairSp')} style={{color:'#2e2e2e', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.repairSp && <RepairInfoValues productName="Repair sp"  handleCheckout={handleCheckout}/>}
      </div>

    </div>
  );
}
