// // import React from 'react';
// // import { Routes, Route } from 'react-router-dom';
// // import './App.css';
// // import Home from './pages/home/Home';
// // import Localizacao from './pages/localizacao/Localizacao';
// // import PlanosOdonto from './pages/planos/PlanosOdonto';
// // import Tratamentos from './pages/tratamentos/Tratamentos';
// // import Quee from './pages/quee/Quee';
// // import Administration from './pages/administracao/Administration';
// // import Cadastro from './pages/cadastro/Cadastro';
// // import AuthPatient from './pages/auth/AuthPatient';
// // import PatientProfile from './pages/patientProfile/PatientProfile';
// // import CadastroPatient from './pages/cadastro/CadastroPatient';
// // import AuthDentist from './pages/auth/AuthDentist';
// // import DentistProfile from './pages/dentistProfile/DentistProfile';
// // import AuthClinic from './pages/auth/AuthClinic';
// // import { AuthProvider } from './context/AuthContext';
// // import PrivateRoute from './utils/PrivateRoute';


// // function App() {
// //   return (
// //     <AuthProvider>
// //     <div className="App">
// // <Routes>
// //   <Route path='/' element={ <Home/> } />
// //   <Route path='/tratamentos' element={ <Tratamentos/> } />
// //   <Route path='/planos' element={ <PlanosOdonto/> } />
// //   <Route path='/contato' element={ <Localizacao/> } />
// //   <Route path='/fila-virtual' element={ <Quee/> } />
  
// // <PrivateRoute>
// //   <Route path='/adm-odk-frtx22wq' element={ <Administration/> } />
// // </PrivateRoute>

// //   <Route path='/cad-odk-kt34hsba' element={ <Cadastro/> } />
// //   <Route path='/cad-patient' element={ <CadastroPatient/> } />
// //   <Route path='/auth-odk-patient-kahjsyehb' element={ <AuthPatient/> } />
// //   <Route path='/auth-odk-dentist-KRairyepr' element={ <AuthDentist/> } />
// //   <Route path='/auth-odk-clinic-Lorjawyorpa' element={ <AuthClinic/> } />
// //   <Route path='/dentist-profile/:id' element={ <DentistProfile/> } />
// //   <Route path='/patient-profile/:id' element={ <PatientProfile/> } />
// // </Routes>
// //     </div>
// //     </AuthProvider>
// //   );
// // }

// // export default App;
// import React from 'react';
// import { Routes, Route } from 'react-router-dom';
// import './App.css';
// import Home from './pages/home/Home';
// import Localizacao from './pages/localizacao/Localizacao';
// import PlanosOdonto from './pages/planos/PlanosOdonto';
// import Tratamentos from './pages/tratamentos/Tratamentos';
// import Quee from './pages/quee/Quee';
// import Administration from './pages/administracao/Administration';
// import Cadastro from './pages/cadastro/Cadastro';
// import AuthPatient from './pages/auth/AuthPatient';
// import PatientProfile from './pages/patientProfile/PatientProfile';
// import CadastroPatient from './pages/cadastro/CadastroPatient';
// import AuthDentist from './pages/auth/AuthDentist';
// import DentistProfile from './pages/dentistProfile/DentistProfile';
// import AuthClinic from './pages/auth/AuthClinic';
// import { AuthProvider } from './context/AuthContext';
// import PrivateRoute from './utils/PrivateRoute';
// import PrivateRouteDentist from './components/auth/PrivateRouteDentist';
// import PrivateRoutePatient from './components/auth/PrivateRoutePatient';

// function App() {
//   return (
//     <AuthProvider>
//       <div className="App">
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/tratamentos" element={<Tratamentos />} />
//           <Route path="/planos" element={<PlanosOdonto />} />
//           <Route path="/contato" element={<Localizacao />} />
//           <Route path="/fila-virtual" element={<Quee />} />
          
//           {/* Usando PrivateRoute para rotas protegidas */}
//           <Route
//             path="/adm-odk-frtx22wq"
//             element={<PrivateRoute element={<Administration />} />}
//           />

//           <Route
//             path="/dentist-profile/:id"
//             element={<PrivateRouteDentist element={<DentistProfile />} />}
//           />

//           <Route
//             path="/patient-profile/:id"
//             element={<PrivateRoutePatient element={<PatientProfile />} />}
//           />


//           <Route path="/cad-odk-kt34hsba" element={<Cadastro />} />
//           <Route path="/cad-patient" element={<CadastroPatient />} />
//           <Route path="/auth-odk-patient-kahjsyehb" element={<AuthPatient />} />
//           <Route path="/auth-odk-dentist-KRairyepr" element={<AuthDentist />} />
//           <Route path="/auth-odk-clinic-Lorjawyorpa" element={<AuthClinic />} />
//           {/* <Route path="/dentist-profile/:id" element={<DentistProfile />} /> */}
//           {/* <Route path="/patient-profile/:id" element={<PatientProfile />} /> */}
//         </Routes>
//       </div>
//     </AuthProvider>
//   );
// }

// export default App;

import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import Localizacao from './pages/localizacao/Localizacao';
import PlanosOdonto from './pages/planos/PlanosOdonto';
import Tratamentos from './pages/tratamentos/Tratamentos';
import Quee from './pages/quee/Quee';
import Administration from './pages/administracao/Administration';
import Cadastro from './pages/cadastro/Cadastro';
import AuthPatient from './pages/auth/AuthPatient';
import PatientProfile from './pages/patientProfile/PatientProfile';
import CadastroPatient from './pages/cadastro/CadastroPatient';
import AuthDentist from './pages/auth/AuthDentist';
import DentistProfile from './pages/dentistProfile/DentistProfile';
import AuthClinic from './pages/auth/AuthClinic';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import PrivateRouteDentist from './components/auth/PrivateRouteDentist';
import PrivateRoutePatient from './components/auth/PrivateRoutePatient';
import { checkBackend } from './utils/check-back-end';
import Maintenance from './pages/maintence/Maintence';
import AdministrationMaster from './pages/administracao/AdministrationMaster';


function App() {
  const [isBackendOnline, setIsBackendOnline] = useState(true);

  useEffect(() => {
    const verifyBackend = async () => {
      const isOnline = await checkBackend();
      setIsBackendOnline(isOnline);
    };

    verifyBackend();
  }, []);

  if (!isBackendOnline) {
    return <Maintenance />;
  }

  return (
    <AuthProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tratamentos" element={<Tratamentos />} />
          <Route path="/planos" element={<PlanosOdonto />} />
          <Route path="/contato" element={<Localizacao />} />
          <Route path="/fila-virtual" element={<Quee />} />
          
          <Route
            path="/adm-odk-frtx22wq"
            element={<PrivateRoute element={<Administration />} />}
          />
          <Route
            path="/dentist-profile/:id"
            element={<PrivateRouteDentist element={<DentistProfile />} />}
          />
          <Route
            path="/patient-profile/:id"
            element={<PrivateRoutePatient element={<PatientProfile />} />}
          />

          <Route path="/master-odk-GrjhjaRhjajkjns98671ADfoi823hjaasj8kjjhb82SS91j" element={<AdministrationMaster />} />

          <Route path="/cad-odk-kt34hsba" element={<Cadastro />} />
          <Route path="/cad-patient" element={<CadastroPatient />} />
          <Route path="/auth-odk-patient" element={<AuthPatient />} />
          <Route path="/auth-odk-dentist-KRairyepr" element={<AuthDentist />} />
          <Route path="/auth-odk-clinic-Lorjawyorpa" element={<AuthClinic />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
