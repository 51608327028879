import React, { useState } from 'react';
import axios from 'axios';
import './css/financeiro-infos.css';
import { BASE_URL } from '../../utils/hostUrl';

const FinanceiroInfos = ({ dentistId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [services, setServices] = useState([]);
  const [totalServiceValue, setTotalServiceValue] = useState(0);
  const [expandedGroups, setExpandedGroups] = useState({});

  const toggleInfos = () => {
    setIsOpen(!isOpen);
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/services-history/by-dentist`, {
        params: {
          dentistId,
          startDate,
          endDate,
        },
      });

      setServices(response.data.filteredServices);
      setTotalServiceValue(response.data.totalServiceValue);
    } catch (error) {
      console.error('Erro ao buscar histórico de serviços:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchServices();
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  
  //   // Corrige as datas para UTC ao enviar ao backend
  //   const adjustedStartDate = new Date(startDate);
  //   adjustedStartDate.setHours(0, 0, 0, 0); // Define o início do dia
  
  //   const adjustedEndDate = new Date(endDate);
  //   adjustedEndDate.setHours(23, 59, 59, 999); // Define o fim do dia
  
  //   setStartDate(adjustedStartDate.toISOString());
  //   setEndDate(adjustedEndDate.toISOString());
  
  //   fetchServices();
  // };
  

  // Função para agrupar os serviços por data
  const groupServicesByDate = (services) => {
    return services.reduce((groups, service) => {
      const date = new Date(service.date).toLocaleDateString('pt-BR');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(service);
      return groups;
    }, {});
  };

  // const groupServicesByDate = (services) => {
  //   return services.reduce((groups, service) => {
  //     // Converte a data diretamente para o formato YYYY-MM-DD
  //     const date = new Date(service.date).toISOString().split('T')[0];
  //     if (!groups[date]) {
  //       groups[date] = [];
  //     }
  //     groups[date].push(service);
  //     return groups;
  //   }, {});
  // };
  



  const groupedServices = groupServicesByDate(services);

  // Função para alternar a visibilidade de um grupo de serviços
  const toggleGroup = (date) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };




  return (
    <div className="financeiro-infos">
      <button onClick={toggleInfos} className="toggle-button">
        {isOpen ? 'Fechar Histórico' : 'Exibir Histórico'}
      </button>
      {isOpen && (
        <div className="infos-container">
          <form onSubmit={handleSubmit} className="filter-form">
            <div className="form-group">
              <label htmlFor="startDate">Data Inicial:</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">Data Final:</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button">
              Buscar
            </button>
          </form>
          <div className="services-list">
            <h3>Histórico de Serviços</h3>
            {Object.keys(groupedServices).map((date) => (
              <div key={date} className="day-group">
                <h4 onClick={() => toggleGroup(date)} className="date-title">
                  {date}
                  <span className="expand-icon">
                    {expandedGroups[date] ? '🔽' : '▶️'}
                  </span>
                </h4>
                {expandedGroups[date] && (
                  <ul>
                    {groupedServices[date].map((service) => (
                      <li key={service.id}>
                        <p><span className='historico-serviço-span'>Manutenção:</span> {service.maintence}</p>
                        <p><span className='historico-serviço-span'>Reparo:</span> {service.repair}</p>
                        <p><span className='historico-serviço-span'>Outros:</span> {service.others}</p>
                        <p><span className='historico-serviço-span'>Horário:</span> {new Date(service.date).toLocaleTimeString('pt-BR')}</p>
                        <p><span className='historico-serviço-span'>Valor do Serviço:</span> R$ {service.service_value.toFixed(2)}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            <div className="total-value">
              <strong>Valor Total dos Serviços: R$ {totalServiceValue.toFixed(2)}</strong>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinanceiroInfos;
