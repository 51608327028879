import React from 'react';
import './css/registerForm.css'
import './css/registerFormMob.css'

const RegisterForm = ({ handleSubmit, patientFormData, addressFormData, selectedOpt, handlePatientChange, handleAddressChange, handleCheckboxChange }) => {
  return (
  <form className='register-form' onSubmit={handleSubmit}>
    <div className='data-address-container'>

      <div className='patient-data-container'>
        <h2 className='dados-do-paciente'>Dados do Paciente</h2>
        <input className='form-input' type="text" name="name" value={patientFormData.name} onChange={handlePatientChange} placeholder="Nome" />
          <input className='form-input'  type="number" name="age" value={patientFormData.age} onChange={handlePatientChange} placeholder="Idade" />
          <input className='form-input'  type="text" name="cpf" value={patientFormData.cpf} onChange={handlePatientChange} placeholder="CPF" />
          <input className='form-input'  type="text" name="rg" value={patientFormData.rg} onChange={handlePatientChange} placeholder="RG" />
          <input className='form-input'  type="email" name="email" value={patientFormData.email} onChange={handlePatientChange} placeholder="E-mail" />
          <input className='form-input'  type="text" name="phone1" value={patientFormData.phone1} onChange={handlePatientChange} placeholder="Telefone 1" />
          <input  className='form-input' type="text" name="phone2" value={patientFormData.phone2} onChange={handlePatientChange} placeholder="Telefone 2" />
          <input className='form-input'  type="text" name="planCardCode" value={patientFormData.planCardCode} onChange={handlePatientChange} placeholder="Código do plano" />
          <input className='form-input'  type="text" name="planName" value={patientFormData.planName} onChange={handlePatientChange} placeholder="Nome do plano" />
            <div className='invi-div-mob-data'></div>
          {/* <div className='options-container'>
      
               <div className="options-container-sec-vip">
                <span className="check-text" >VIP</span>
                  <input className="input-check"
                  
                   type="checkbox" name="vip" checked={selectedOpt === 'vip'} onChange={handleCheckboxChange} />
                
               </div>

               <div className="options-container-sec-vip">
                <span className="check-text" >SMART</span>
                  <input className="input-check" type="checkbox" name="smart" checked={selectedOpt === 'smart'} onChange={handleCheckboxChange} />
                
               </div>

               <div className="options-container-sec-vip">
                <span className="check-text" >BÁSICO</span>
                  <input className="input-check" type="checkbox" name="basic" checked={selectedOpt === 'basic'} onChange={handleCheckboxChange} />
                
               </div>
                  

          </div> */}

          </div>

    <div className='patient-address-container'>
      <h2>Endereço</h2>
            <div className='patient-address-container-sec'>
                  <input className='form-input'  type="text" name="postal_code" value={addressFormData.postal_code} onChange={handleAddressChange} placeholder="CEP" />
                    <input className='form-input'  type="text" name="country" value={addressFormData.country} onChange={handleAddressChange} placeholder="País" />
                    <input className='form-input' type="text" name="street" value={addressFormData.street} onChange={handleAddressChange} placeholder="Rua" />
                    <input className='form-input'  type="text" name="city" value={addressFormData.city} onChange={handleAddressChange} placeholder="Cidade" />
                    <input className='form-input'  type="number" name="address_number" value={addressFormData.address_number} onChange={handleAddressChange} placeholder="Nº endereço" />
                    <div className='invi-div'></div>
                    <div className='invi-div-mob-add'></div>
            </div>
     </div>

      </div>

          <div className='cadastrar-btn-container'>
        <button className = 'adm-btns' type="submit">Cadastrar</button>
          </div>
    </form>

  );
};

export default RegisterForm;
