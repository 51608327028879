import React from 'react';
import axios from 'axios';
import '../queeAdm/css/queRemoverBtn.css'; // Estilo CSS para o botão
import { BASE_URL } from '../../../utils/hostUrl';

axios.defaults.baseURL = `${BASE_URL}`;

export default function QueeLastPositionBtn({ personId, noRemoverBtn }) {
  const handleRemove = () => {
    // Exibir alerta de confirmação
    const confirmDelete = window.confirm('Deseja mover este paciente para o fim da fila ?');
    if (confirmDelete) {
      // Enviar solicitação de exclusão para a rota
      axios.delete('quee/to-last-position', { data: { id: personId } })

    }
  };

  return (
    (noRemoverBtn &&
      <button className="round-button-L" onClick={handleRemove}>
        L
      </button>
    )
  );
}
