import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';


const PrivateRouteDentist = ({ element }) => {
  const [isValidToken, setIsValidToken] = useState(null); // Estado para armazenar o resultado da verificação do token
  


  useEffect(() => {
   
    
    const verifyToken = async () => {
      const token = localStorage.getItem('accessTokenDentist'); // Recupera o token do localStorage
      if (!token) {
        setIsValidToken(false); // Token não existe
        return;
      }
      setIsValidToken(true)
    };

    verifyToken(); // Chama a função para verificar o token
  }, []);

  // Enquanto o token está sendo verificado, pode mostrar um carregamento
  if (isValidToken === null) {
    return <div>Carregando...</div>;
  }
  // Redireciona com base na validade do token
  return isValidToken ? element : <Navigate to="/auth-odk-dentist-KRairyepr" />;
};

export default PrivateRouteDentist;
