import React, { useState } from 'react';
import './css/administration.css';
import './css/administrationMob.css';
import Header from '../../components/header/Header';
import QueeCall from '../../components/quee/queeAdm/QueeCall';
import QueeManagerBtn from '../../components/quee/queeAdm/QueeManagerBtn';
import QueeContainer from '../../components/quee/queeContainer/QueeContainer';
import RegisterRedirectBtn from '../../components/cadastro/RegisterRedirectBtn';
import QueeClearBtn from '../../components/quee/queeAdm/QueeClearBtn';
import DentistSelector from '../../components/quee/dentistSelector/DentistSelector';
import PatientInServiceInfos from '../../components/patietInServiceInfos/PatientInservice';
import RegisterDentist from '../../components/cadastro/RegisterDentist';
import ConcluirBeneficio from '../../components/adm/ConcluirBeneficio';
import { BASE_URL } from '../../utils/hostUrl';
import NoticeBoard from '../../components/patientProfile/NoticeBoard';


export default function Administration() {
  const [noUperCover] = useState('no-uper-cover')
  const [noRemoverBtn] = useState('no-remover-btn')
  const [noRoundBtn] = useState('no-round-btn')
  const [updateTrigger, setUpdateTrigger] = useState(false);
  

  const handleCallNext = async (room) => {

  try {
    // Chama a rota para o próximo paciente com o parâmetro room na URL
    const response = await fetch(`${BASE_URL}/quee/next?room=${room}`, {
      method: 'DELETE',
    });

    if (response.ok) {


      setUpdateTrigger((prev) => !prev);
    } else {
      throw new Error('Erro ao chamar próximo paciente');
    }
  } catch (error) {
    console.error('Erro ao chamar próximo paciente:', error);
    alert('Erro ao chamar próximo paciente. Por favor, tente novamente.');
  }
};


  return (
    
    <div className='adm'>
      <Header />
      <div className='control-painel'>
        <div className='painel-de-controle-div'>
        <span className='painel-de-controle'>Painel de Controle</span>
        </div>
      <div className='adm-btns-container'>
     
        <div className='register-btns-container'>
      
          <div className='cadastro-title-container'>
          <p>Area Cadastral:</p>
          </div>
         
      <RegisterDentist />
      <RegisterRedirectBtn />  
        </div>
       
      <div className='operation-btns-container'>
    
      <div className='cadastro-title-container'>
      <p>Area Operacional:</p>   
      </div>
      <div className='notice-board-container'>
      <NoticeBoard admOnly={true} />
      </div>

      <ConcluirBeneficio/>
          <div className='dentist-selector-container-adm'>
                <DentistSelector />
          </div>
      <div className='operation-btns-div'>
      <QueeCall onCallNext={handleCallNext} />
      <QueeManagerBtn />
      <QueeClearBtn />
      </div>
      </div>
      
      </div>
      <PatientInServiceInfos updateTrigger={updateTrigger}/>
      </div>
      <QueeContainer noUperCover={noUperCover} noRemoverBtn={noRemoverBtn} noRoundBtn={noRoundBtn} showDocument={true} />
    </div>
    
  );
}
