// src/pages/Maintenance.js
import React from 'react';

const Maintenance = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>Estamos em manutenção</h1>
      <p>Por favor, volte mais tarde.</p>
    </div>
  );
};

export default Maintenance;
