import React, { useEffect } from 'react';
import './css/productsCardMob.css'
import StandardContainer from './StandardContainer';
import SmartContainer from './SmartContainer';
import VipContainer from './VipContainer';
import SpecialContainer from './SpecialContainer';
// import StandardBoxExp from './ProductsBoxExplanation/StandardBoxExp';
// import SmartBoxExp from './ProductsBoxExplanation/SmartBoxExp';
// import VipBoxExp from './ProductsBoxExplanation/VipBoxExp';
// import SpecialBoxExp from './ProductsBoxExplanation/SpecialBoxExp';

export default function ProductsCard({productsInfo, handleCheckout}) {
  // Estado para controlar quais containers estão expandidos
  // const [expandedCategory, setExpandedCategory] = useState(null);
  // Função para alternar o container expandido
  // const toggleExpand = (category) => {
  //   setExpandedCategory(expandedCategory === category ? null : category);
  // };
 
  useEffect(() => {
    
  }, [productsInfo])
  
  // Separar os valores por categorias
  const standard = {
    standard1: productsInfo ? productsInfo.standard1 : 'carregando',
    standard3: productsInfo ? productsInfo.standard3 : 'carregando',
    standard6: productsInfo ? productsInfo.standard6 : 'carregando',
    standard12: productsInfo ? productsInfo.standard12 : 'carregando',
  };

  const vip = {
    vip1: productsInfo ? productsInfo.vip1 : 'carregando',
    vip3: productsInfo ? productsInfo.vip3 : 'carregando',
    vip6: productsInfo ? productsInfo.vip6 : 'carregando',
    vip12: productsInfo ? productsInfo.vip12 : 'carregando',
  };

  const smart = {
    smart1: productsInfo ? productsInfo.smart1 : 'carregando',
    smart3: productsInfo ? productsInfo.smart3 : 'carregando',
    smart6: productsInfo ? productsInfo.smart6 : 'carregando',
    smart12: productsInfo ? productsInfo.smart12 : 'carregando',
  };

  const special = {
    special1: productsInfo ? productsInfo.special1 : 'carregando',
    special3: productsInfo ? productsInfo.special3 : 'carregando',
    special6: productsInfo ? productsInfo.special6 : 'carregando',
    special12: productsInfo ? productsInfo.special12 : 'carregando',
    specialIn: productsInfo ? productsInfo.specialIn : 'carregando',
  };

  // Função para renderizar os detalhes de uma categoria
  // const renderCategoryDetails = (category) => {
  //   switch (category) {
  //     case 'standard':
  //       return <StandardContainer standard={standard} handleCheckout={handleCheckout}  />;
  //     case 'smart':
  //       return <SmartContainer smart={smart} handleCheckout={handleCheckout} />;
  //     case 'vip':
  //       return <VipContainer vip={vip} handleCheckout={handleCheckout} />;
  //     case 'special':
  //       return <SpecialContainer special={special}  handleCheckout={handleCheckout}/>;
  //     default:
  //       return null;
  //   }
  // };
  return (
  <div>
    {productsInfo ?
    <div className='products-card-main-container'>
      <div className='products-card-container'>
        <div className='standard-title-box'>
        <span className='box-title-font'>Standard</span>
        </div>
        <StandardContainer standard={standard} handleCheckout={handleCheckout}  />
        {/* <StandardBoxExp toggleExpand={() => toggleExpand('standard')} />
        {expandedCategory === 'standard' && renderCategoryDetails('standard')} */}
      </div>

      <div className='products-card-container'>
        <div className='smart-title-box'>
        <span className='box-title-font'>Smart</span>
        </div>
        <SmartContainer smart={smart} handleCheckout={handleCheckout} />
        {/* <SmartBoxExp toggleExpand={() => toggleExpand('smart')} />
        {expandedCategory === 'smart' && renderCategoryDetails('smart')} */}
      </div>

      <div className='products-card-container'>
        <div className='vip-title-box'>
        <span className='box-title-font'>VIP</span>
        </div>
        <VipContainer vip={vip} handleCheckout={handleCheckout} />
        {/* <VipBoxExp toggleExpand={() => toggleExpand('vip')} />
        {expandedCategory === 'vip' && renderCategoryDetails('vip')} */}
      </div>

      <div className='products-card-container'>
        <div className='special-title-box'>
        <span className='box-title-font-special'>Special</span>
        </div>
        {/* <SpecialBoxExp toggleExpand={() => toggleExpand('special')} />
        {expandedCategory === 'special' && renderCategoryDetails('special')} */}
        <SpecialContainer special={special}  handleCheckout={handleCheckout}/>
      </div>
    </div> : <h2>Carregando a loja virutal...</h2>
}
  </div>
  );
}


