export default function birthDateToAge(dataNascimento) {
  const [dia, mes, ano] = dataNascimento.split('/').map(Number);
  const dataNascimentoObj = new Date(ano, mes - 1, dia); // Ajusta o mês (0-11)

  const hoje = new Date();
  let idade = hoje.getFullYear() - dataNascimentoObj.getFullYear();

  // Verifica se o aniversário já passou neste ano
  const aniversarioEsteAno = new Date(hoje.getFullYear(), mes - 1, dia);
  if (hoje < aniversarioEsteAno) {
    idade--;
  }

  
  return idade;
}
