// 

import React, { useState, useEffect } from 'react';
import './css/queeInstructionsMob.css';
import './css/queeInstructions.css';
import InServiceClearBtn from '../queeAdm/InServiceClearBtn';
import formatName from '../../../utils/formatName';
import { io } from 'socket.io-client';
import { BASE_URL } from '../../../utils/hostUrl';

export default function QueeInstructions({ noRoundBtn }) {
  const [inService, setInservice] = useState('Ninguém');

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/quee/in-service`);
      const data = await response.json();
      if (data.length === 0) {
        setInservice('Ninguém');
      } else {
        setInservice(formatName(data.name));
      }
    } catch (error) {
      console.error('Erro ao obter os dados do backend:', error);
    }
  };

  useEffect(() => {
    // Fetch inicial para carregar os dados
    fetchData();

    // Inicializando o socket
    const socket = io(`${BASE_URL}`, {
      transports: ['websocket'], // Apenas WebSocket
    });

    // Ouvindo eventos do socket
    socket.on('toInService', async () => {
      await fetchData();
    });

    // Cleanup para desconectar o socket ao desmontar o componente
    return () => {
      socket.disconnect();
    };
  }, []); // Dependência vazia para rodar apenas no primeiro render

  return (
    <div className="quee-instructions">
      <div className="instructions-container">
        <span className="atention">Atenção!</span>
        <span className="instructions">
          Esteja presente ao ser chamado, caso contrário será movido para o fim da fila.
        </span>
      </div>
      <div className="inService-container">
        <div className="in-service-container-second">
          <span className="inService">Em atendimento:</span>
          <span className="inServicePatient">{inService}</span>
          <InServiceClearBtn noRoundBtn={noRoundBtn} />
        </div>
      </div>
    </div>
  );
}
