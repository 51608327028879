import React, { useEffect, useState } from 'react';
import './css/info-values.css';

export default function SmartInfoValues({ productName, handleCheckout }) {
  const [productInfo, setProductInfo] = useState({});
  const pix = true;

  const processProductName = (productName) => {
    let quantity = 0;
    let refValue = '';
    
    // Processamento baseado no productName
    if (productName === 'Smart 1') {
      quantity = 1;
      refValue = 'smart1';
    } else if (productName === 'Smart 3') {
      quantity = 3;
      refValue = 'smart3';
    } else if (productName === 'Smart 6') {
      quantity = 6;
      refValue = 'smart6';
    } else if (productName === 'Smart 12') {
      quantity = 12;
      refValue = 'smart12';
    }

    // Define o objeto com base no productName processado
    const productInfo = {
      product: `Smart ${quantity === 1 ? 'One' : quantity === 3 ? 'Tri' : quantity === 6 ? 'Six' : 'Dozen'}`,
      quantity,
      refValue,
      refId: 'smart',
      description:'Crédito para Manutenção de aparelho'
    };

    // Atualiza o estado com o objeto processado
    setProductInfo(productInfo);
  };

  useEffect(() => {
    processProductName(productName);
  }, [productName]);

  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Smart 1' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho convencional.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 1 crédito para limpeza dentária(profilaxia).</li>
        </ul>
      )}
      {productName === 'Smart 3' && (
        <ul className='product-itens-list'>
          <li>&bull; 3 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 110,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 3 créditos para limpeza dentária(profilaxia).</li>
        </ul>
      )}
      {productName === 'Smart 6' && (
        <ul className='product-itens-list'>
          <li>&bull; 6 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 100,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 6 créditos para limpeza dentária(profilaxia).</li>
          <li>&bull; 1 crédito para restauração dentária (obturação).</li>
        </ul>
      )}
      {productName === 'Smart 12' && (
        <ul className='product-itens-list'>
          <li>&bull; 12 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 90,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 12 créditos para limpeza dentária(profilaxia).</li>
          <li>&bull; 2 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 crédito ou voucher de desconto para 1 extração dentária* (consulte as regras).</li>
        </ul>
      )}
      <button onClick={()=> handleCheckout(productInfo, pix)} className='button-info-values'>PIX</button>
      <button onClick={()=> handleCheckout(productInfo)} className='button-info-values'>CRÉDITO</button>
    </div>
  );
}
