import { Link } from 'react-router-dom'
import logo from '../../assets/icons/logo.svg'
import './css/headerOrtho.css'
import smartMenu from '../../assets/icons/smartMenu.svg'
import MediaMenu from '../mediaMenu/MediaMenu'
import { useState } from 'react'

export default function HeaderOrtho() {

  const [ShowMenu , setShow ] = useState(false);

const setMenu = () => {
  setShow(!ShowMenu)
}

const handleLogout = () => {
  localStorage.removeItem('accessTokenPatient'); // Limpa o token do paciente
};

  return (
    <div>
    <header className='header-ort'>

      <div className='header-container-ort'>
        <Link to='/'
        className='link-text-ort'>
        <div className='logo-container-ort'>
      
          <img
            className='logo-img'
            src={logo} alt="logotipo" />
          <span className='logo-text-ort'>ORTHO START</span>
      
        </div>
        </Link>
        <span className='logo-text-media-ort'>ORTHO START</span>
        <div
        onClick={setMenu} 
        className='menu-btn'>
          <img 
          className='menu-btn'
          src={smartMenu} alt="" />
        </div>

        <div className='links-container'>
          {/* <Link to='/tratamentos'
          className='link-text'>Tratamentos
          </Link> */}
{/* 
          <Link to='/planos'
          className='link-text'>Planos Odontológicos
          </Link> */}

          <Link to='/contato'
          className='link-text'>Contato e Localização
          </Link>

                <Link
            to="/auth-odk-patient"
            className="link-text"
            onClick={handleLogout}
          >
            Sair
          </Link>

        </div>

      </div>
    </header>
      {ShowMenu && <MediaMenu /> }
    </div>
  )
}
