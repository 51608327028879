import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import './css/loginPatientForm.css';
import validationSchemaLogin from '../../utils/validationSchemaLogin';
import { BASE_URL } from '../../utils/hostUrl';

const LoginClinicForm = () => {
 
  const navigate = useNavigate();

  // Função para lidar com a requisição de login
  const loginRequest = async (values, setErrors, setSubmitting) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/clinic`, values);
      if (response.data && response.data.user && response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);     
         navigate(`/adm-odk-frtx22wq`);
        // setTimeout(() => {
        //   navigate(`/adm-odk-frtx22wq`);
        // }, 5000);
      } else {
        setErrors({ password: 'Erro inesperado. Tente novamente.' });
      }
      
      if (response.data.error) {
        setErrors({password : response.data.error})
      }
    } catch (error) {
      
      if (error.response) {
        setErrors({ password: error.response.data.message || 'Dados de login inválidos' });
      } else {
        setErrors({ password: 'Dados de login inválidos' });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='login-form-container-ort'>
      <div className='login-form-ort'>
        <Formik
          initialValues={{ doc: '', password: '' }}
          validationSchema={validationSchemaLogin}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            await loginRequest(values, setErrors, setSubmitting); // Chama a função externa de requisição
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className='login-form-container-sec-ort'>
              {/* Campo Documento (Email ou CPF) */}
              <div className='text-box-container-ort'>
                <ErrorMessage className='error-message-ort' name="doc" component="div" />
                <Field
                  placeholder='Email ou CPF(números)'
                  className="field-form-ort-emailCPF"
                  name="doc"
                  type="text"
                  autoComplete="email" 
                />
              </div>

              {/* Campo Senha */}
              <div className='text-box-container-ort'>
                <ErrorMessage className='error-message-ort' name="password" component="div" />
                <Field
                  placeholder='Senha'
                  className="field-form-ort"
                  name="password"
                  type="password"
                   autoComplete="current-password"
                />
              </div>

              {/* Botão de Submissão */}
              <div>
                <button
                  className='adm-btns'
                  type="submit"
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginClinicForm;
