import React, { useState } from 'react';
import axios from 'axios';
import './css/manual-credits-add.css';
import { BASE_URL } from '../../utils/hostUrl';

const ManualCreditsAddForm = () => {
  const [document, setDocument] = useState('');
  const [patientId, setPatientId] = useState(null);
  const [formData, setFormData] = useState({
    productQtd: 1,
    productCategory: 'standard',
    value: 0,
    paymentMethod: 'Pix',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleVerifyPatient = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${BASE_URL}/patients/findByDocs`, { document });
      setPatientId(response.data.id);
    } catch (err) {
      setError('Paciente não encontrado.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddCredits = async (e) => {
    e.preventDefault();
    if (!patientId) {
      setError('Paciente não verificado.');
      return;
    }

    const productReference =
      formData.productCategory !== 'specialIn'
        ? `${formData.productCategory}${formData.productQtd}`
        : formData.productCategory;

    const transactionData = {
      patient_id: patientId,
      status: 'PAID',
      status_description: 'Pagamento manual / Presencial',
      value: (formData.value)*100,
      product_name: formData.productCategory === 'specialIn' ? formData.productCategory 
      : ` ${formData.productCategory}-${formData.productQtd}`,
      payment_method: formData.paymentMethod,
      date: new Date(),
    };

    const creditsData = {
      patientId,
      productQtd: formData.productQtd,
      productCategory: formData.productCategory,
      productReference,
    };

    try {
      const confirmation = window.confirm('Deseja realmente adicionar os créditos?');
      if (!confirmation) return;

      await axios.post(`${BASE_URL}/credits/manual-add-Rjso0972AADerau6721jasdu5`, {
        creditsData,
        referenceValue: productReference,
        transactionData,
      });
      alert('Créditos adicionados com sucesso!');

      // Limpar os estados e formulários
      setDocument('');
      setPatientId(null);
      setFormData({
        productQtd: 1,
        productCategory: 'standard',
        value: 0,
        paymentMethod: 'Pix',
      });
    } catch (err) {
      setError('Erro ao adicionar créditos.');
    }
  };

  return (
    <div className="manual-credits-container">
      <form onSubmit={handleVerifyPatient} className="verify-patient-form">
        <h2 className="verify-patient-title">Verificar Paciente</h2>
        <input
          type="text"
          className="verify-patient-input"
          placeholder="Documento do Paciente"
          value={document}
          onChange={(e) => setDocument(e.target.value)}
          required
        />
        <button
          type="submit"
          className="verify-patient-button"
          disabled={loading}
        >
          {loading ? 'Verificando...' : 'Verificar'}
        </button>
        {error && <p className="verify-patient-error">{error}</p>}
      </form>

      {patientId && (
        <form onSubmit={handleAddCredits} className="add-credits-form">
          <h2 className="add-credits-title">Adicionar Créditos</h2>

          <label className="add-credits-label">Quantidade de Créditos:</label>
          <select
            value={formData.productQtd}
            className="add-credits-select"
            onChange={(e) => setFormData({ ...formData, productQtd: Number(e.target.value) })}
          >
            <option value={1}>1</option>
            <option value={3}>3</option>
            <option value={6}>6</option>
            <option value={12}>12</option>
          </select>

          <label className="add-credits-label">Categoria do Produto:</label>
          <select
            value={formData.productCategory}
            className="add-credits-select"
            onChange={(e) => setFormData({ ...formData, productCategory: e.target.value })}
          >
            <option value="standard">Standard</option>
            <option value="smart">Smart</option>
            <option value="vip">VIP</option>
            <option value="special">Special</option>
            <option value="specialIn">SpecialIn</option>
            <option value="repair">Repair</option>
          </select>

          <label className="add-credits-label">Valor:</label>
          <input
            type="number"
            min="0"
            className="add-credits-input"
            value={formData.value}
            onChange={(e) => setFormData({ ...formData, value: Number(e.target.value) })}
            required
          />

          <label className="add-credits-label">Método de Pagamento:</label>
          <select
            value={formData.paymentMethod}
            className="add-credits-select"
            onChange={(e) => setFormData({ ...formData, paymentMethod: e.target.value })}
          >
            <option value="Pix">Pix</option>
            <option value="Cartão de Crédito">Cartão de Crédito</option>
            <option value="Dinheiro">Dinheiro</option>
          </select>

          <button type="submit" className="add-credits-button">
            Adicionar Créditos
          </button>
        </form>
      )}
    </div>
  );
};

export default ManualCreditsAddForm;
