import React, { useState } from 'react';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import './css/register.css'
import RegisterDentistForm from './RegisterDentistForm';
import { BASE_URL } from '../../utils/hostUrl';


const RegisterDentist = () => {
  const navigate = useNavigate();
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [patientFormData, setPatientFormData] = useState({
    name: '',
    cnpj_cpf: '',
    cro:'',
    email: '',
    password:'',
    phone1: '',
    phone2: '',
  });

  const handlePatientChange = (e) => {
    const { name, value } = e.target;
    setPatientFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

const handleSubmit = async (formData) => {
  try {
    // Removendo o campo confirmPassword antes de enviar
    const { confirmPassword, ...formDataToSubmit } = formData;
    await axios.post(`${BASE_URL}/dentists`, {
      ...formDataToSubmit
    });
    
    alert('Dentista cadastrado com sucesso!');
    // Limpar o formulário
    setPatientFormData({
      name: '',
      cnpj_cpf: '',
      cro: '',
      email: '',
      password: '',
      phone1: '',
      phone2: '',
    });
  } catch (error) {
    console.error('Erro ao cadastrar dentista:', error);
    alert('Erro ao cadastrar dentista. Por favor, tente novamente.');
  }
};

  const handleClick = () => {
    navigate('/adm-odk-frtx22wq'); // Redirects to the desired route
  };


  return (
    <div className='register'>

      <div className='cad-dent-container'>
      <button className='adm-btns' onClick={() => setShowRegisterForm(!showRegisterForm)}>Cadastrar Dentista</button> 
      </div>
      {showRegisterForm && <RegisterDentistForm handleSubmit={handleSubmit}
      patientFormData={patientFormData}
      handlePatientChange={handlePatientChange}
    />}
  
   
    </div>
  );
};

export default RegisterDentist;
