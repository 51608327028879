import React, { useState, useEffect } from 'react';
import './css/concluir-beneficio.css';
import { BASE_URL } from '../../utils/hostUrl';

const GerenciarBeneficios = () => {
  const [formType, setFormType] = useState(null); // 'check' | 'remove'
  const [document, setDocument] = useState('');
  const [creditType, setCreditType] = useState('');
  const [dentists, setDentists] = useState([]);
  const [selectedDentist, setSelectedDentist] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchDentists = async () => {
      try {
        const response = await fetch(`${BASE_URL}/dentists/simple`);
        if (!response.ok) {
          throw new Error('Erro ao buscar lista de dentistas');
        }
        const data = await response.json();
        setDentists(data);
      } catch (error) {
        setErrorMessage(error.message || 'Erro inesperado');
      }
    };

    fetchDentists();
  }, []);

  const handleCheckCredits = async () => {
    try {
      const response = await fetch(`${BASE_URL}/benefits/manual-check`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ document, creditType }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Erro ao verificar créditos');
      }
      
      
      if (data.error) {
        setSuccessMessage(null);
        setErrorMessage(data.error);
      }else {
        setErrorMessage(null); 
        setSuccessMessage('Créditos suficientes!');
      }

      setDocument('');
    } catch (error) {
      setErrorMessage(error.message || 'Erro inesperado');
      setSuccessMessage(null);
    }
  };

  const handleRemoveCredits = async () => {
    if (!selectedDentist) {
      setErrorMessage('Selecione um dentista para concluir o serviço');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/benefits/manual-remove`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          document,
          creditType,
          quantity: 1, // Exemplo com quantidade fixa
          dentistId: selectedDentist,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
       return (data.message || 'Erro ao concluir benefício');
      }
      
      if (data.error) {
        setSuccessMessage(null);
        setErrorMessage(data.error);
      }else{
      setSuccessMessage('Benefício concluído com sucesso!');
      setErrorMessage(null);
      } 
      
      setDocument('');
      setSelectedDentist('');
    } catch (error) {
      setErrorMessage(error.message || 'Erro inesperado');
      setSuccessMessage(null);
    }
  };

  const resetMessages = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  return (
<div className="form-container-concluir-bene">
  <h2>Gerenciar Benefícios</h2>

  <div className="button-group-concluir-bene">
    <button
      className="button-concluir-bene"
      onClick={() => {
        setFormType('check');
        resetMessages();
      }}
    >
      Verificar Créditos
    </button>
    <button
      className="button-concluir-bene"
      onClick={() => {
        setFormType('remove');
        resetMessages();
      }}
    >
      Concluir Serviço
    </button>
  </div>

  {formType === 'check' && (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleCheckCredits();
      }}
    >
      <input
        type="text"
        placeholder="Documento do paciente"
        value={document}
        onChange={(e) => setDocument(e.target.value)}
        className="input-field-concluir-bene"
      />
      <select
        value={creditType}
        onChange={(e) => setCreditType(e.target.value)}
        className="select-field-concluir-bene"
      >
        <option value="">Selecione o tipo de crédito</option>
        <option value="cleaning_credits">Limpeza</option>
        <option value="obturation_credits">Restauração</option>
        <option value="extraction_credits">Extração</option>
        <option value="canal_credits">Canal</option>
        <option value="crown_credits">Prótese</option>
        <option value="whitening_credits">Clareamento Cas.</option>
        <option value="laser_whit_credits">Clareamento Las.</option>
      </select>
      <button type="submit" className="submit-button-concluir-bene">
        Verificar Créditos
      </button>
    </form>
  )}

  {formType === 'remove' && (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleRemoveCredits();
      }}
    >
      <input
        type="text"
        placeholder="Documento do paciente"
        value={document}
        onChange={(e) => setDocument(e.target.value)}
        className="input-field-concluir-bene"
      />
      <select
        value={creditType}
        onChange={(e) => setCreditType(e.target.value)}
        className="select-field-concluir-bene"
      >
        <option value="">Selecione o tipo de crédito</option>
        <option value="cleaning_credits">Limpeza</option>
        <option value="obturation_credits">Restauração</option>
        <option value="extraction_credits">Extração</option>
        <option value="canal_credits">Canal</option>
        <option value="crown_credits">Prótese</option>
        <option value="whitening_credits">Clareamento Cas.</option>
        <option value="laser_whit_credits">Clareamento Las.</option>
      </select>
      <select
        value={selectedDentist}
        onChange={(e) => setSelectedDentist(e.target.value)}
        className="select-field-concluir-bene"
        required
      >
        <option value="">Selecione um dentista</option>
        {dentists.map((dentist) => (
          <option key={dentist.id} value={dentist.id}>
            {dentist.name}
          </option>
        ))}
      </select>
      <button type="submit" className="submit-button-concluir-bene">
        Concluir Serviço
      </button>
    </form>
  )}

  {errorMessage && <p className="error-message-concluir-bene">{errorMessage}</p>}
  {successMessage && <p className="success-message-concluir-bene">{successMessage}</p>}
</div>

  );
};

export default GerenciarBeneficios;
