import React, { useState } from 'react';
import './css/queeEntryMob.css';
// import './css/queeEntry.css';

function QueeEntry({ onAddPerson , error, patientQuee , patientDoc , useTitularCred }) {
  const [documento, setDocumento] = useState('');
  const [category, setCategory] = useState(undefined);
  const [cleaning, setCleaning] = useState(undefined);
  const [repair, setRepair] = useState(undefined);
  const [isUsingTitularCredits, setIsUsingTitularCredits] = useState(false);

  
  
  const handleSubmit = (e) => {
    e.preventDefault();


    if ((documento.length <= 11 && documento.length > 0) || patientDoc ) {
      onAddPerson({
        doc:patientDoc ? patientDoc: documento,
        category,
        cleaning,
        repair,
        useTitularCred: isUsingTitularCredits,
      });

      setDocumento('');
      setCategory(undefined);
      setCleaning(null);
      setRepair(undefined);
    } else {
      alert('O documento deve ter no máximo 11 caracteres');
    }
  };

  const isButtonDisabled = false
    // !documento || (!category && cleaning === undefined && !repair);

  // Função para alternar seleção única dentro de um grupo
  const handleCheckboxChange = (value, setState, currentValue) => {
    setState(currentValue === value ? undefined : value);
  };

  return (
    <div className='quee-entry-form-main'>
    <form className='quee-entry-form' onSubmit={handleSubmit}>
      {!patientDoc && <input
        className="input-doc"
        type="text"
        value={patientDoc? patientDoc : documento}
        onChange={(e) => setDocumento(e.target.value)}
        placeholder="Apenas números: RG ou CPF ou COD"
        maxLength={11}
      />}

      <div className="quee-entry-category">
        <p><span className='manutencao'>Manutenção:</span></p>
        
          <div className='stand-smart-group'>
          <label >
            <input
              type="checkbox"
              checked={category === 'standard'}
              onChange={() =>
                handleCheckboxChange('standard', setCategory, category)
              }
            />
            <span className='categories'>Standard</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={category === 'smart'}
              onChange={() =>
                handleCheckboxChange('smart', setCategory, category)
              }
            />
           <span className='categories'>Smart</span> 
          </label>
          </div>
          <div className='vip-special-group'>
          <label>
            <input
              type="checkbox"
              checked={category === 'vip'}
              onChange={() => handleCheckboxChange('vip', setCategory, category)}
            />
            <span className='categories'>Vip</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={category === 'special'}
              onChange={() =>
                handleCheckboxChange('special', setCategory, category)
              }
            />
            <span className='categories'>Special</span>
          </label>
          </div>
          <div>
          <label>
            <input
              type="checkbox"
              checked={category === 'specialIn'}
              onChange={() =>
                handleCheckboxChange('specialIn', setCategory, category)
              }
            />
            <span className='categories'>Instal. Special</span>
          </label>
          </div>
      </div>

      <div className="quee-entry-cleaning">
        <p><span className='limpeza'>Limpeza:</span></p>
        <div className='cleaning-sub-div'>
        <label >
          <input 
            
            type="checkbox"
            checked={cleaning === 'cleaning_credits'}
            onChange={() => handleCheckboxChange('cleaning_credits', setCleaning, cleaning)}
          />
         <span className='sim-nao'> Sim </span>
        </label>
        <label >
          <input 
            type="checkbox"
            checked={cleaning === 'Nenhum'}
            onChange={() => handleCheckboxChange('Nenhum', setCleaning, cleaning)}
          />
         <span className='sim-nao'> Não </span>
        </label>
        </div>
      </div>

      <div className="quee-entry-repair">
        <label><span className='reparo'>Reparo:</span></label>
        <div>
          <label>
            <input
              type="checkbox"
              checked={repair === 'repair_min_credits'}
              onChange={() =>
                handleCheckboxChange('repair_min_credits', setRepair, repair)
              }
            />
             <span className='repairs'>Nível 1</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={repair === 'repair_med_credits'}
              onChange={() =>
                handleCheckboxChange('repair_med_credits', setRepair, repair)
              }
            />
           <span className='repairs'> Nível 2</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={repair === 'repair_max_credits'}
              onChange={() =>
                handleCheckboxChange('repair_max_credits', setRepair, repair)
              }
            />
            <span className='repairs'>Nível 3</span>
          </label>

          <label>
            <input
              type="checkbox"
              checked={repair === 'repair_sp_credits'}
              onChange={() =>
                handleCheckboxChange('repair_sp_credits', setRepair, repair)
              }
            />
            <span className='repairs'>Special</span>
          </label>
        </div>
      </div>
     
      <div className='use-titular-cred-checkbox'>
          <label>
            <input
              type="checkbox"
              checked={isUsingTitularCredits}
              onChange={() => setIsUsingTitularCredits(!isUsingTitularCredits)}
            />
           
           
            <span>Usar créditos do meu titular.</span>
           
          </label>
        </div>

      {
      error && 
      <div className='error-message-container'>
        <p className="error-message-quee-entry">{error}</p>
      </div>
      }


      <button
        className="quee-entry-btn"
        type="submit"
        disabled={isButtonDisabled}
      >
        Entrar na Fila
      </button>
    </form>
    </div>
  );
}

export default QueeEntry;
