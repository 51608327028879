import React, { useEffect, useState } from 'react'
import HeaderOrtho from '../../components/header/HeaderOrtho'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import PatientInServiceInfos from '../../components/patietInServiceInfos/PatientInservice';
import FinanceiroInfos from '../../components/dentistFinanceiro/FinanceiroInfos';
import './css/dentist-profile.css'
import QueeContainer from '../../components/quee/queeContainer/QueeContainer';
import { BASE_URL } from '../../utils/hostUrl';

export default function DentistProfile() {
  const { id } = useParams();

  const [dentistInfos, setDentistInfos] = useState(null);
  const isDentist = true;
  const navigate = useNavigate();


  const fetchDetist = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/dentists/${id}`);
      const dentistData = response.data;
      // Remove o campo password do objeto de paciente
      const { password, ...dentistWithoutPassword } = dentistData;
      setDentistInfos(dentistWithoutPassword); // Salva o objeto sem o campo password
    } catch (error) {
      console.error('Erro ao buscar os dados do dentista:', error);
    }
  };

  const verifyToken = async () => {
    const token = localStorage.getItem('accessTokenDentist'); // Recupera o token do localStorage
    try {
      const response = await axios.post(`${BASE_URL}/dentists/verify-dentist-token`, {
        authorization: token,
      });
     
        if (response.data.dentistVerifyId !== Number(id)) {
          navigate(`/auth-odk-dentist-KRairyepr`)
        }    
  
    } catch (error) {
      console.error('Erro ao verificar o token:', error);
      navigate("/auth-odk-dentist-KRairyepr")
    }
  };

 

    // useEffect para fazer o fetch quando o componente for montado
    useEffect(() => {   
      verifyToken();
      fetchDetist();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]); // Reexecuta o efeito sempre que o 'id' mudar

  return (
    <div className='dentist-profile-main'>
       <HeaderOrtho/>
       <div className='welcome-container'>
       <p className='welcome'>Bem vindo(a)! Dr(a). {dentistInfos && dentistInfos.name} </p>
       </div>
       <QueeContainer noUperCover={true} dentistQuee = {true} />
       <PatientInServiceInfos isDentist = {isDentist} id = {id} />
       <FinanceiroInfos  dentistId = {id}/>
      </div>
  )
}
