import axios from 'axios';
import { useState } from 'react';
import './css/register.css';
import RegisterFormByPatient from './RegisterFormByPatient';
import { BASE_URL } from '../../utils/hostUrl';

const RegisterDependantByPatient = () => {

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formValues) => {
    const { name, age, cpf, rg, email, password, phone1, phone2, planCardCode, planName } = formValues;

    const patientFormData = {
      name,
      age,
      cpf,
      rg,
      email,
      password,
      phone1,
      phone2,
      planCardCode,
      planName,
      clinic_id: 1,
      ortho: false,
      vip: false,
      orthoPlanId: 1,
    };

    const addressFormData = {
      postal_code: '',
      street: '',
      city: '',
      country: '',
      address_number: 0,
    };

    setIsLoading(true); // Ativa o spinner ao iniciar a requisição

    try {
      const response = await axios.post(`${BASE_URL}/patients/createByUser`, {
        createPatientUserDto: patientFormData,
        createAddressDto: addressFormData,
      });

      if (response.data.error) {
        alert(response.data.error);
        setIsLoading(false); // Desativa o spinner em caso de erro
        return;
      }

      alert('Cadastro efetuado com sucesso!');

    } catch (error) {
      alert('Erro inesperado ao criar o cadastro. Por favor, tente novamente.');
    } finally {
      setIsLoading(false); // Desativa o spinner ao finalizar a requisição
    }
  };

  return (
    <div className="register">
      {isLoading ? (
        <div className="spinner">
          <div className="loader"></div>
          <p>Carregando...</p>
        </div>
      ) : (
        <div className='cad-dep-form-sub-div'>
          
          <RegisterFormByPatient handleSubmit={handleSubmit} />
        </div>
      )}
    </div>
  );
};

export default RegisterDependantByPatient;
