import React, { useState } from 'react';
import './css/treatment-sheets.css'

function TreatmentSheets({ patients, allocation }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');

  // Filtrando fichas por período de data
  const filteredSheets = patients[allocation.patient_id].TreatmentSheet.filter(sheet => {
    const createdAt = new Date(sheet.createdAt);
    const startDate = filterStartDate ? new Date(filterStartDate) : null;
    const endDate = filterEndDate ? new Date(filterEndDate) : null;
    return (
      (!startDate || createdAt >= startDate) &&
      (!endDate || createdAt <= endDate)
    );
  });
  

  return (
    <div className="treatment-container">
 
      <button className="toggle-btn" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? 'Retrair' : 'Exibir'} Fichas de Tratamento
      </button>

      {isExpanded && (
        <div>
          <div className="filter-container">
            <label>
              Início:
              <input
                type="date"
                value={filterStartDate}
                onChange={e => setFilterStartDate(e.target.value)}
              />
            </label>
            <label>
              Fim:
              <input
                type="date"
                value={filterEndDate}
                onChange={e => setFilterEndDate(e.target.value)}
              />
            </label>
          </div>

          <ul className="treatment-list">
            {filteredSheets.map(sheet => (
              <li key={sheet.id} className="treatment-card">
                <strong>Tratamento:</strong> {sheet.name}  <strong>Dente:</strong> {sheet.tooth || '0'} 
                <strong>Região:</strong> {sheet.region || 'S/Info'}
                <br />
                <strong>Descrição:</strong> {sheet.description}
                <br />
                <strong>Observação:</strong> {sheet.obs || 'Nenhuma'}
                <br />
                <strong>Criado em:</strong> {new Date(sheet.createdAt).toLocaleString('pt-BR')}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default TreatmentSheets;

