import React, { useState } from 'react';
import './css/patient-services.css'

export default function PatientTransactionsHistory({ patientInfos }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const transactionHistory = patientInfos.TransactionsHistory || [];

  // Filtrar histórico por datas
  // const filteredHistory = transactionHistory.filter((service) => {
  //   if (!startDate && !endDate) return true;
  //   const serviceDate = new Date(service.date).getTime();
  //   const start = startDate ? new Date(startDate).getTime() : null;
  //   const end = endDate ? new Date(endDate).getTime() : null;

  //   return (!start || serviceDate >= start) && (!end || serviceDate <= end);
  // });
  const filterPaids = transactionHistory.filter((t)=>{
  return t.status === 'PAID';
  })

  // const filteredHistory = filterPaids.filter((service) => {

  
  //   if (!startDate && !endDate) return true; // Se não houver filtros de data, retorna o item
  
  //   const serviceDate = new Date(service.date).getTime();
  //   const start = startDate ? new Date(startDate).getTime() : null;
  //   const end = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;
    
  //   // Verifica as condições de data
  //   return (!start || serviceDate >= start) && (!end || serviceDate <= end);
  // });

  function filterByDate(services, startDate, endDate) {
    // Converter startDate e endDate para objetos Date no fuso horário local
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Ajustar endDate para 23:59:59 para garantir que o dia inteiro seja incluído
    end.setHours(23, 59, 59, 999);

    // Comparar as datas sem considerar o horário específico
    return services.filter((service) => {
      // Converter a data do serviço para um objeto Date
      const serviceDate = new Date(service.date);

      // Criar um novo objeto de data removendo as horas (usando toISOString e reformatando)
      const serviceDay = new Date(serviceDate.toISOString().split('T')[0]);

      // Verificar se a data do serviço está dentro do intervalo
      return serviceDay >= start && serviceDay <= end;
    });
  }
  

  // Selecionar últimos 3 serviços ou todos se filtrado
  const displayedHistory =
    startDate || endDate ? filterByDate(filterPaids,startDate, endDate ) : filterPaids.slice(-3);

  return (
    <div className="patient-history-container-transa">
      <div className='transa-title-div'>
      <h2>Transações Efetuadas</h2>
      </div>
      {/* Filtros de Data */}
      <div className="filter-container">
        <label>
          Data Inicial:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          Data Final:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
      </div>

      {/* Carrossel */}
      {displayedHistory.length > 0 ? (
        <div className="carousel-container-services">
          {displayedHistory.map((service, index) => (
            <div className="card" key={index}>
              <p>
                <strong>Data:</strong> {new Date(service.date).toLocaleDateString('pt-BR')}
              </p>
              <p>
                <strong>Produto:</strong> {(service.product_name)}
              </p>
              <p>
                <strong>Metodo:</strong> {service.payment_method}
              </p>
              <p>
                <strong>Valor:</strong> {(service.value)/100},00
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-services">Nenhuma transação realizada.</p>
      )}
    </div>
  );
}
