import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/dentist-selector.css'
import { BASE_URL } from '../../../utils/hostUrl';

const DentistSelector = () => {
  const [dentists, setDentists] = useState([]);
  const [selectedDentists, setSelectedDentists] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDentistId, setSelectedDentistId] = useState('');
  const [selectedConsultorio, setSelectedConsultorio] = useState('');
  const [consultorios, setConsultorios] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [dentistIdSaved, SetDentistIdSaved] = useState('');
  const [dentistNameModal, SetDentistNameModal] = useState('');
  
  // Carregar dados ao montar o componente
  useEffect(() => {
    const loadData = async () => {
      const storedDentists = localStorage.getItem('selectedDentists');
      if (storedDentists) {
        setSelectedDentists(JSON.parse(storedDentists));
      } else {
        try {
          const response = await axios.get(`${BASE_URL}/dentist-allocations`);
          setSelectedDentists(response.data);
          localStorage.setItem('selectedDentists', JSON.stringify(response.data));
        } catch (error) {
          console.error('Erro ao carregar dados do backend:', error);
        }
      }

      try {
        const response = await axios.get(`${BASE_URL}/dentists/simple`);
        setDentists(response.data);
      } catch (error) {
        console.error('Erro ao buscar dentistas:', error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedDentists', JSON.stringify(selectedDentists));
  }, [selectedDentists]);

  const handleConsultorioChange = (e) => {
    setConsultorios(Number(e.target.value));
    setSelectedDentists([]);
    setSuccessMessage('');
    localStorage.removeItem('selectedDentists');
  };

  const handleSelectDentist = async () => {
    const selectedDentist = dentists.find((dentist) => dentist.id === Number(selectedDentistId));
    if (selectedDentist && selectedConsultorio) {
      const newAllocation = {
        dentist_id: selectedDentist.id,
        dentist_name: selectedDentist.name,
        room: Number(selectedConsultorio),
      };

      try {
        await axios.post(`${BASE_URL}/dentist-allocations/create`, newAllocation);
        setSelectedDentists((prev) => [...prev, newAllocation]);
        setDentists((prev) => prev.filter((dentist) => dentist.id !== Number(selectedDentistId)));
        setSuccessMessage(`Dr.(a) ${selectedDentist.name} foi alocado ao consultório ${selectedConsultorio}.`);
        setSelectedDentistId('');
        setSelectedConsultorio('');
      } catch (error) {
        console.error('Erro ao alocar dentista:', error);
      }
    }
  };

  const handleRemoveDentist = async (dentistId) => {
    try {
      await axios.delete(`${BASE_URL}/dentist-allocations/delete/${dentistId}`);
      const removedDentist = selectedDentists.find((dentist) => dentist.dentist_id === dentistId);
      setSelectedDentists((prev) => prev.filter((dentist) => dentist.dentist_id !== dentistId));
      setDentists((prev) => [
        ...prev,
        { id: removedDentist.dentist_id, name: removedDentist.dentist_name },
      ]);
      setSuccessMessage(`O dentista ${removedDentist.dentist_name} foi removido.`);
    } catch (error) {
      let errorMessage = 'Houve um erro ao remover o dentista. Tente novamente mais tarde.';   
      // Verificando o tipo de erro e definindo uma mensagem apropriada
      if (error.response && error.response.status === 404) {
        errorMessage = 'Alocação para o dentista não encontrada.';
      } else if (error.response && error.response.status === 400) {
        errorMessage = 'Impossível remover dentista com atendimento em andamento.';
      }     
      // Exibe apenas o erro para o usuário
      setErrorMessage(errorMessage);
      // Impede a exposição do erro no console
      // Apenas se necessário, exiba uma mensagem genérica no console
      console.error('Erro ao remover dentista');
    }
  };
  
  const handleResetSelection = async () => {
    try {
      // Chamada para o endpoint de deletar todas as alocações
      const response = await fetch(`${BASE_URL}/dentist-allocations/delete-all`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        setSelectedDentists([]);
        setConsultorios(0);
        localStorage.removeItem('selectedDentists');
        setSuccessMessage('Seleção reiniciada e todas as alocações foram deletadas.');
      } else {
        // Verifica o status da resposta para definir mensagens específicas
        let errorMessage = 'Falha ao reiniciar as alocações. Tente novamente.';
        if (response.status === 400) {
          errorMessage = 'Não é possível reiniciar: Existem alocações associadas a pacientes.';
        } else if (response.status === 404) {
          errorMessage = 'Nenhuma alocação foi encontrada para reiniciar.';
        }
        setErrorMessage(errorMessage);
      }
    } catch (error) {
      // Define uma mensagem genérica para falhas na conexão
      setErrorMessage('Erro ao se conectar ao servidor. Verifique a conexão e tente novamente.');
    } finally {
      // Fecha o modal independentemente do sucesso ou erro
      setShowModal(false);
    }
  };
  
  return (
    
    <div className='dentist-selector-main'>
      {!consultorios && selectedDentists.length === 0 && (
        <div className='dentist-selector-content-first'>
          <p className='qnt-de-consultorios-p'> Quantidade de consultórios:</p>
          <select className='qnt-consultorios-select'
          onChange={handleConsultorioChange}>
            <option value="">Selecione</option>
            {[...Array(10).keys()].map((n) => (
              <option key={n + 1} value={n + 1}>
                {n + 1}
              </option>
            ))}
          </select>
        </div>
      )}


      {consultorios > 0 && (
        <div className='dentist-selector-content-first'>
          <p className='qnt-de-consultorios-p'>Dentista & Consultório:</p>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          
          <select className='select-alocacao'
            value={selectedDentistId}
            onChange={(e) => setSelectedDentistId(e.target.value)}
          >
            <option value="">Selecione um dentista</option>
            {dentists.map((dentist) => (
              <option key={dentist.id} value={dentist.id}>
                {dentist.name}
              </option>
            ))}
          </select>

          {/* Filtrando os consultórios disponíveis */}
          <select  className='select-alocacao'
            value={selectedConsultorio}
            onChange={(e) => setSelectedConsultorio(e.target.value)}
          >
            <option value="">Selecione um consultório</option>
            {[...Array(consultorios).keys()]
              .map((n) => n + 1) // Convertendo índice para número de consultório
              .filter(
                (roomNumber) =>
                  !selectedDentists.some((dentist) => dentist.room === roomNumber)
              ) // Filtrando consultórios já alocados
              .map((availableRoom) => (
                <option key={availableRoom} value={availableRoom}>
                  Consultório {availableRoom}
                </option>
              ))}
          </select>

          <button className='alocar-btn'
            onClick={handleSelectDentist}
            disabled={!selectedDentistId || !selectedConsultorio}
          >
            Alocar
          </button>
          </div>
 
        
      )}
           {selectedDentists.length > 0 && (
        <div className='dentist-selector-content-final'>
          <p className='qnt-de-consultorios-p'>Dentistas e Consultórios Alocados:</p>
          <ul>
            {selectedDentists.map((dentist) => (
              
              <li className='dentist-consultorio-li'
               key={dentist.dentist_id}>
               <p className='p-dentist-consultorio'>{dentist.dentist_name} - Consultório {dentist.room}</p>
                <button className='remover-btn'
                  onClick={() => [SetDentistNameModal(dentist.dentist_name), SetDentistIdSaved(dentist.dentist_id), setShowModal2(true)]}
                  
                >
                  Remover
                </button>
                
              </li>
              
            ))}
          </ul>
          <button className='reiniciar-btn'
            onClick={() => setShowModal(true)} 
          >
            Reiniciar Alocação
          </button>
        </div>
      )}
     
      {errorMessage && (
        <p style={{ color: 'red', marginBottom: '10px' }}>
          {errorMessage}
        </p>
      )}

      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
          <h3>Confirmar Re-Alocação</h3>
          <p>Tem certeza que deseja REINICIAR a seleção?</p>
          <div className='modal-btns-container'>
          <button className='modal-btn-confirm' 
          onClick={handleResetSelection} >
            Sim
          </button>
          <button className='modal-btn-cancel'
          onClick={() => setShowModal(false)}>
            Não
          </button>
          </div>
          </div>
        </div>
      )}

        {showModal2 && (
        <div className='modal' 
  
        >
          <div className='modal-content'>
            <h3>Confirmar Remoção</h3>
          <p>Deseja REMOVER Dr(a){' '}{dentistNameModal}?</p>
          <div className='modal-btns-container'>
          <button className='modal-btn-confirm' 
           onClick={()=> [handleRemoveDentist(dentistIdSaved), setShowModal2(false)]} >
            Sim
          </button>

          <button className='modal-btn-cancel'
          onClick={() => setShowModal2(false)} >
            Não
          </button>
          </div>
          </div>
          </div>
      )}
    </div>
  );
};

export default DentistSelector;
