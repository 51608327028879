import React, { useEffect, useState } from 'react';
import './css/info-values.css'

export default function RepairInfoValues({ productName , handleCheckout}) {
  const [productInfo, setProductInfo] = useState({});
  const pix = true;
  // Função para processar a string productName em um objeto
  const processProductName = (productName) => {
    let quantity = 0;
    let refValue = '';
    
    // Processamento baseado no productName
    if (productName === 'Repair 1') {
      quantity = 1;
      refValue = 'repair1';
    } else if (productName === 'Repair 2') {
      quantity = 1;
      refValue = 'repair2';
    } else if (productName === 'Repair 3') {
      quantity = 1;
      refValue = 'repair3';
    } else if (productName === 'Repair sp') {
      quantity = 1;
      refValue = 'repairSp';
    }   
    // Define o objeto com base no productName processado
    const productInfo = {
      product: `Reparo ${refValue === 'repair1' ? 'Nivel 1' : refValue === 'repair2' ? 'Nivel 2' : refValue === 'repair3' ? 'Nivel 3' : 'Special'}`,
      quantity,
      refValue,
      refId: 'repair',
      description:'Crédito para Reparos de aparelho'
    };

    // Atualiza o estado com o objeto processado
    setProductInfo(productInfo);
  };

  useEffect(() => {
    processProductName(productName);
  }, [productName]);

  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Repair 1' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para reparo NIVEL 1 de aparelho convencional.</li>
        </ul>
      )}
      {productName === 'Repair 2' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para reparo NIVEL 2 de aparelho convencional.</li>
          
        </ul>
      )}
      {productName === 'Repair 3' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para reparo NIVEL 3 de aparelho convencional.</li>

          
        </ul>
      )}
            {productName === 'Repair sp' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para reparo de aparelho SPECIAL.</li>

          
        </ul>
      )}
      <button onClick={()=> handleCheckout(productInfo, pix)} className='button-info-values'>PIX</button>
      <button onClick={()=> handleCheckout(productInfo)} className='button-info-values'>CRÉDITO</button>
    </div>
  );
}
