import React from 'react'
import HeaderOrtho from '../../components/header/HeaderOrtho'
// import Footer from '../../components/footer/Footer'
import AuthCover from '../../components/auth/AuthCover'
import './css/authPatient.css'
import LoginDentistForm from '../../components/auth/LoginDentistForm'

export default function AuthDentist() {
  return (
    <div className='auth-dentist-main'>
      <HeaderOrtho/>
      <AuthCover/>
      <LoginDentistForm/>
      {/* <Footer/> */}
      </div>
  )
}
