import React, { useEffect, useState } from 'react';
import './css/info-values.css';

export default function VipInfoValues({ productName, handleCheckout }) {

  const [productInfo, setProductInfo] = useState({});
  const pix = true;
  const processProductName = (productName) => {
    let quantity = 0;
    let refValue = '';
    
    // Processamento baseado no productName
    if (productName === 'Vip 1') {
      quantity = 1;
      refValue = 'vip1';
    } else if (productName === 'Vip 3') {
      quantity = 3;
      refValue = 'vip3';
    } else if (productName === 'Vip 6') {
      quantity = 6;
      refValue = 'vip6';
    } else if (productName === 'Vip 12') {
      quantity = 12;
      refValue = 'vip12';
    }

    // Define o objeto com base no productName processado
    const productInfo = {
      product: `Vip ${quantity === 1 ? 'One' : quantity === 3 ? 'Tri' : quantity === 6 ? 'Six' : 'Dozen'}`,
      quantity,
      refValue,
      refId: 'vip',
      description:'Crédito para Manutenção de aparelho'
    };

    // Atualiza o estado com o objeto processado
    setProductInfo(productInfo);
  };

  useEffect(() => {
    processProductName(productName);
  }, [productName]);
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Vip 1' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho convencional.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 1 crédito para limpeza dentária (profilaxia).</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
        </ul>
      )}
      {productName === 'Vip 3' && (
        <ul className='product-itens-list'>
          <li>&bull; 3 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 195,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 3 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 1 crédito para restauração dentária (obturação).</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
        </ul>
      )}
      {productName === 'Vip 6' && (
        <ul className='product-itens-list'>
          <li>&bull; 6 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 190,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 6 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 2 créditos para restauração dentária (obturação).</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
          <li>&bull; 1 crédito ou voucher de desconto para 1 extração dentária* (consulte as regras)</li>
          
        </ul>
      )}
      {productName === 'Vip 12' && (
        <ul className='product-itens-list'>
          <li>&bull; 12 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 180,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 12 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 3 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 crédito ou voucher para tratamento de canal.* (consulte as regras)</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
          <li>&bull; 1 kit de clareamento caseiro (plaquinha + gel).</li>
          <li>&bull; 1 kit de higiene bucal para aparelhos.(exclusivo do pacote)</li>
          <li>&bull; 2 créditos ou vouchers de desconto para extração dentária.* (consulte as regras)</li>
          <li>&bull; 1 voucher de 30% de desconto, para 1 prótese unitária.</li>
        </ul>
      )}
      <button onClick={()=> handleCheckout(productInfo, pix)} className='button-info-values'>PIX</button>
      <button onClick={()=> handleCheckout(productInfo)} className='button-info-values'>CRÉDITO</button>
    </div>
  );
}
