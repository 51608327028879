import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from './hostUrl';

const PrivateRoute = ({ element }) => {
  const [isValidToken, setIsValidToken] = useState(null); // Estado para armazenar o resultado da verificação do token

  useEffect(() => {
   
    
    const verifyToken = async () => {
      const token = localStorage.getItem('accessToken'); // Recupera o token do localStorage
      if (!token) {
        setIsValidToken(false); // Token não existe
        return;
      }

      try {
        const response = await axios.post(`${BASE_URL}/clinic-user/verify-clinic-token`, {
          authorization: token,
        });
        setIsValidToken(response.data); // Atualiza o estado com a resposta da API (true ou false)
      } catch (error) {
        
        setIsValidToken(false); // Caso ocorra erro, o token é considerado inválido
      }
    };

    verifyToken(); // Chama a função para verificar o token
  }, []);

  // Enquanto o token está sendo verificado, pode mostrar um carregamento
  if (isValidToken === null) {
    return <div>Carregando...</div>;
  }

  // Redireciona com base na validade do token
  return isValidToken ? element : <Navigate to="/auth-odk-clinic-Lorjawyorpa" />;
};

export default PrivateRoute;
