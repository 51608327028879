// QueeCall.js
import React, { useState, useEffect } from 'react';
import './css/queeCall.css'

export default function QueeCall({ onCallNext }) {
  const [showConsultorioButtons, setShowConsultorioButtons] = useState(false);
  const [dentists, setDentists] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedConsultorio, setSelectedConsultorio] = useState(null);

  // Carrega os dados do localStorage ao montar o componente
  useEffect(() => {
    const selectedDentists = JSON.parse(localStorage.getItem('selectedDentists')) || [];
    setDentists(selectedDentists);
  }, []);

  const handleCallNext = () => {
    const selectedDentists = JSON.parse(localStorage.getItem('selectedDentists')) || [];
    setDentists(selectedDentists);
    setShowConsultorioButtons(true); // Exibe os botões de consultórios
  };

  const handleConsultorioSelection = (dentist) => {
    // Exibe o modal de confirmação após selecionar o consultório
    setSelectedConsultorio(dentist);
    setShowModal(true); // Exibe o modal
    setShowConsultorioButtons(false); // Esconde os botões de consultório
  };

  const handleModalConfirm = () => {
    // Fecha o modal e chama o paciente
    setShowModal(false);
    onCallNext(selectedConsultorio.room); // Chama a função para chamar o próximo paciente
  };

  const handleModalCancel = () => {
    // Fecha o modal sem chamar o paciente
    setShowModal(false);
    setShowConsultorioButtons(true); // Exibe novamente os botões de consultório
  };

  return (
    <div>
      <button className='adm-btns' onClick={handleCallNext}>
        Alocar Paciente
      </button>

      {/* Caixa de Botões para selecionar o consultório */}
      {showConsultorioButtons && (
        <div className="consultorio-buttons-container">
          {dentists.map((dentist) => (
            <button
              className='consultorio-btn'
              key={dentist.dentist_id}
              onClick={() => handleConsultorioSelection(dentist)}
            >
              Consultório {dentist.room}
            </button>
          ))}
        </div>
      )}

      {/* Modal de Confirmação */}
      {showModal && (
        
        <div className="modal">
          <div className="modal-content">
            <h3>Confirmar Chamada</h3>
            <p>
              Você está prestes a chamar o paciente para o{' '}
              <strong>Consultório {selectedConsultorio.room}</strong>, com o(a) dentista{' '}
              <strong>{selectedConsultorio.dentist_name}</strong>.
            </p>
            <div className='modal-btns-container'>
              <button className='modal-btn-confirm' onClick={handleModalConfirm}>Confirmar</button>
              <button className='modal-btn-cancel' onClick={handleModalCancel}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
