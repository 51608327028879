import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './css/registerForm.css';

const RegisterDentistForm = ({ handleSubmit }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required('O nome é obrigatório'),
    cnpj_cpf: Yup.string().required('O CNPJ ou CPF é obrigatório'),
    cro: Yup.string().required('O CRO é obrigatório'),
    email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
    phone1: Yup.string().required('O telefone é obrigatório'),
    phone2: Yup.string(),
    password: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha deve ter pelo menos 6 caracteres'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
      .required('A confirmação de senha é obrigatória'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      cnpj_cpf: '',
      cro: '',
      email: '',
      phone1: '',
      phone2: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <form className='register-form' onSubmit={formik.handleSubmit}>
      <div>
        <div className='dentist-data-container'>
          <h2 className='dados-do-dentista'>Dados do Dentista</h2>
          <div className='form-field'>
          {formik.errors.name && <div className="error-message">{formik.errors.name}</div>}
            <input
              className='form-input'
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="Nome"
            />
            
          </div>
          <div className='form-field'>
          {formik.errors.cnpj_cpf && <div className="error-message">{formik.errors.cnpj_cpf}</div>}
            <input
              className='form-input'
              type="text"
              name="cnpj_cpf"
              value={formik.values.cnpj_cpf}
              onChange={formik.handleChange}
              placeholder="CNPJ/CPF"
            />
            
          </div>
          {formik.errors.cro && <div className="error-message">{formik.errors.cro}</div>}
          <div className='form-field'>
            <input
              className='form-input'
              type="text"
              name="cro"
              value={formik.values.cro}
              onChange={formik.handleChange}
              placeholder="CRO"
            />
            
          </div>
          <div className='form-field'>
          {formik.errors.email && <div className="error-message">{formik.errors.email}</div>}
            <input
              className='form-input'
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="E-mail"
            />
            
          </div>
          <div className='form-field'>
          {formik.errors.phone1 && <div className="error-message">{formik.errors.phone1}</div>}
            <input
              className='form-input'
              type="text"
              name="phone1"
              value={formik.values.phone1}
              onChange={formik.handleChange}
              placeholder="Telefone 1"
            />
            
          </div>
          <div className='form-field'>
          {formik.errors.phone2 && <div className="error-message">{formik.errors.phone2}</div>}
            <input
              className='form-input'
              type="text"
              name="phone2"
              value={formik.values.phone2}
              onChange={formik.handleChange}
              placeholder="Telefone 2"
            />
            
          </div>
          <div className='form-field'>
          {formik.errors.password && <div className="error-message">{formik.errors.password}</div>}

            <input
              className='form-input'
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder="Senha"
            />
          </div>
          <div className='form-field'>
          {formik.errors.confirmPassword && <div className="error-message">{formik.errors.confirmPassword}</div>}

            <input
              className='form-input'
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              placeholder="Confirmar Senha"
            />
          </div>
        </div>
      </div>

      <div className='cadastrar-btn-container'>
        <button className='cad-dentist-btn' type="submit">Cadastrar</button>
      </div>
    </form>
  );
};

export default RegisterDentistForm;
