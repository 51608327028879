// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// function PatientDependants({ beneficiaryId }) {
//   const [dependants, setDependants] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [newDependantDoc, setNewDependantDoc] = useState('');
//   const [addLoading, setAddLoading] = useState(false);
//   const [addError, setAddError] = useState(null);

//   // Fetch dependants on load
//   useEffect(() => {
//     const fetchDependants = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(`/dependences/${beneficiaryId}`);
//         setDependants(response.data);
//       } catch (err) {
//         setError(err.response?.data?.message || 'An error occurred');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDependants();
//   }, [beneficiaryId]);

//   // Add a new dependant
//   const handleAddDependant = async () => {
//     if (!newDependantDoc.trim()) {
//       setAddError('Please enter a valid document.');
//       return;
//     }

//     try {
//       setAddLoading(true);
//       setAddError(null);

//       const response = await axios.post(`/dependences/${beneficiaryId}`, {
//         document: newDependantDoc,
//       });

//       setDependants([...dependants, response.data]); // Add the new dependant to the list
//       setNewDependantDoc(''); // Clear input
     
      
//     } catch (err) {
//       setAddError(err.response?.data?.message || 'Failed to add dependant.');
//     } finally {
//       setAddLoading(false);
//     }
//   };

//   // Loading and error states
//   if (loading) return <p>Loading dependants...</p>;
//   if (error) return <p>Error: {error}</p>;
//   if (dependants.length === 0 && !addLoading)
//     return <p>No dependants found for this beneficiary.</p>;
//   console.log(dependants);
//   return (
//     <div>
//       <h1>Dependants</h1>
//      { dependants.length > 0 &&
//       <ul>
//         {dependants.map((dependant) => (
//           <li key={dependant.id}>
//             <strong>{dependant.dependant.name}</strong>
//           </li>
//         ))}
//       </ul>
// }
//       <div>
//         <h2>Add New Dependant</h2>
//         <input
//           type="text"
//           placeholder="Enter dependant's document"
//           value={newDependantDoc}
//           onChange={(e) => setNewDependantDoc(e.target.value)}
//         />
//         <button onClick={handleAddDependant} disabled={addLoading}>
//           {addLoading ? 'Adding...' : 'Add Dependant'}
//         </button>
//         {addError && <p style={{ color: 'red' }}>{addError}</p>}
//       </div>
//     </div>
//   );
// }

// export default PatientDependants;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/dependants.css'
import { BASE_URL } from '../../utils/hostUrl';
import RegisterDependantByPatient from '../cadastro/RegisterDependantByPatient';

function PatientDependants({ beneficiaryId }) {
  const [dependants, setDependants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newDependantDoc, setNewDependantDoc] = useState('');
  const [addLoading, setAddLoading] = useState(false);
  const [addError, setAddError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [selectedDependant, setSelectedDependant] = useState(null);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
    const toggleVisibility = () => {
      setIsVisible((prev) => !prev);
    };
  

  // Fetch dependants on load
  useEffect(() => {
    const fetchDependants = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/dependences/${beneficiaryId}`);     
       
      setDependants(response.data);
      
      
      } catch (err) {
        setError(err.response?.data?.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchDependants();
  }, [beneficiaryId]);

  // Add a new dependant
  const handleAddDependant = async () => {
    if (!newDependantDoc.trim()) {
      setAddError('Por favor digite um documento válido.');
      return;
    }

    try {
      setAddLoading(true);
      setAddError(null);

      const response = await axios.post(`${BASE_URL}/dependences/${beneficiaryId}`, {
        document: newDependantDoc,
      });
     
      if (response.data.error) {
        setAddError(response.data.error);
      } else {
        
        setDependants(response.data); // Add the new dependant to the list
      }
      
      setNewDependantDoc(''); // Clear input
    } catch (err) {
      setAddError(err.response?.data?.message || 'Não foi possivel adicionar dependente.');
    } finally {
      setAddLoading(false);
    }
  };

  // Remove a dependant
  const handleRemoveDependant = async () => {
    if (!selectedDependant) return;
    try {
      setRemoveLoading(true);
    const response =  await axios.delete(`${BASE_URL}/dependences/${selectedDependant.dependant_id}`);
    
    if (response.data.error) {
      setShowModalError(true)
      setTimeout(() => {
        setShowModal(false);
        setShowModalError(false)
      }, 2500);
    }else {

      setDependants(dependants.filter(d => d.dependant_id !== selectedDependant.dependant_id));
      setShowModal(false);
      setSelectedDependant(null);
       
    }

      
    } catch (err) {
      setShowModalError(true)
    } finally {
      setRemoveLoading(false);
    }
  };

  // Loading and error states
  if (loading) return <p>carregando dependentes...</p>;
  if (error) return <p>Erro: {error}</p>;


  return (

<div>
  {dependants.length === 0 ? (
    <span>Nenhum dependente adicionado.</span>
  ) : (
    <ul className="dependants-list">
      {dependants.map((dependant) => (
        <li key={dependant.id}>
          <strong>{dependant.dependant.name }</strong>
          <button onClick={() => { setShowModal(true); setSelectedDependant(dependant); }}>
            Remover
          </button>
        </li>
      ))}
    </ul>
  ) }

  <div className="add-dependant">
    <h2>Adicionar dependente</h2>
    <input
      type="number"
      placeholder="Digite o CPF"
      value={newDependantDoc}
      onChange={(e) => setNewDependantDoc(e.target.value)}
    />
    <button onClick={handleAddDependant} disabled={addLoading}>
      {addLoading ? 'Adicionando...' : 'Adicionar'}
    </button>
    {addError && <p>{addError}</p>}
  </div>

  <div className="cad-dep-interno">
      <span>Cadastre aqui seu dependente</span>
      <button onClick={toggleVisibility} className="toggle-button">
        {isVisible ? 'Fechar Cadastro' : 'Cadastrar'}
      </button>
      <div className='cad-dep-interno-form-container'>
      {isVisible && <RegisterDependantByPatient />}  
      </div>
    </div>

  {showModal && (
    <div className="modal-remove-dependant">
      <div>
        {showModalError && <p style={{"color": "red", "fontSize":"1rem"}}>Impossível Remover : paciente em atendimento...</p>}
        <p>Tem certeza que deseja remover o dependente {selectedDependant?.dependant.name}?</p>
        <button onClick={handleRemoveDependant} disabled={removeLoading}>
          {removeLoading ? 'Removendo...' : 'Sim, remover'}
        </button>
        <button onClick={() => [setShowModal(false), setShowModalError(false)]}>Cancelar</button>
      </div>
    </div>
  )}
  
</div>

  );

  
}

export default PatientDependants;
