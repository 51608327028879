import React, { useEffect, useState } from 'react';
import './css/info-values.css';

export default function SpecialInfoValues({ productName, handleCheckout }) {
  // Renderização condicional baseada no nome do produto passado por props
  const [productInfo, setProductInfo] = useState({});
  const pix = true;
  const processProductName = (productName) => {
    let quantity = 0;
    let refValue = '';
    
    // Processamento baseado no productName
    if (productName === 'Special 1') {
      quantity = 1;
      refValue = 'special1';
    } else if (productName === 'Special 3') {
      quantity = 3;
      refValue = 'special3';
    } else if (productName === 'Special 6') {
      quantity = 6;
      refValue = 'special6';
    } else if (productName === 'Special 12') {
      quantity = 12;
      refValue = 'special12';
    } else if (productName === 'Instalacao') {
      quantity = 1;
      refValue = 'specialIn';
    }


    // Define o objeto com base no productName processado
    const productInfo = {
      product: `Special ${quantity === 1 ? 'One' : quantity === 3 ? 'Tri' : quantity === 6 ? 'Six' : 'Dozen'}`,
      quantity,
      refValue,
      refId: 'special',
      description:'Crédito para Manutenção de aparelho'
    };

    // Atualiza o estado com o objeto processado
    setProductInfo(productInfo);
  };

  useEffect(() => {
    processProductName(productName);
  }, [productName]);

  return (
    <div className='product-details'>
      {productName === 'Special 1' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho estético ou autoligado.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 1 crédito para limpeza dentária (profilaxia).</li>
        </ul>
      )}
      {productName === 'Special 3' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 3 créditos para manutenção de aparelho estético ou autoligado.</li>
          <li className='uni-credit-value-sp'>Cada crédito sai por R$ 240,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 3 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 1 crédito para restauração dentária (obturação).</li>
        </ul>
      )}
      {productName === 'Special 6' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 6 créditos para manutenção de aparelho estético ou autoligado.</li>
          <li className='uni-credit-value-sp'>Cada crédito sai por R$ 230,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 6 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 2 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 voucher de 30% de desconto para 1 extração.</li>
        </ul>
      )}
      {productName === 'Special 12' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 12 créditos para manutenção de aparelho estético ou autoligado.</li>
          <li className='uni-credit-value-sp'>Cada crédito sai por R$ 220,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 12 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 3 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 crédito ou voucher de desconto para tratamento de canal.* (consulte as regras)</li>
          <li>&bull; 1 kit de clareamento caseiro (plaquinha + gel).</li>
          <li>&bull; 1 kit de higiene bucal para aparelhos.(exclusivo do pacote)</li>
          <li>&bull; 1 crédito ou voucher de desconto para extração dentária.* (consulte as regras)</li>
        </ul>
      )}

      {productName === 'Instalacao' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; Instalaçao de aparelho estético ou autoligado.</li>
          <li>&bull; 1 crédito para manutenção de aparelho estético ou autoligado.</li>
        </ul>
      )}
      <button onClick={()=> handleCheckout(productInfo, pix)} className='button-info-values'>PIX</button>
      <button onClick={()=> handleCheckout(productInfo)} className='button-info-values'>CRÉDITO</button>
    </div>
  );
}
