import React, { useState } from 'react';
import './css/productsCardMob.css'
import RepairContainer from './RepairContainer';
import RepairBoxExp from './ProductsBoxExplanation/RepairBoxExp';

export default function RepairCard({productsInfo,handleCheckout}) {
  // Estado para controlar quais containers estão expandidos
  const [expandedCategory, setExpandedCategory] = useState(null);

  // Função para alternar o container expandido
  const toggleExpand = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  // Separar os valores por categorias
  const repair = {
    repair1: productsInfo.repair1,
    repair2: productsInfo.repair2,
    repair3: productsInfo.repair3,
    repairSp: productsInfo.repairSp
  };

  // Função para renderizar os detalhes de uma categoria
  const renderCategoryDetails = (category) => {
    switch (category) {
      case 'repair':
        return <RepairContainer repair={repair} handleCheckout={handleCheckout}  />;
 
      default:
        return null;
    }
  };
  return (
  <div>
    <div className='repair-card-main-container'>
      <div className='products-card-container'>
        <div className='repair-title-box'>
        <span className='box-title-font-repair'>Reparos</span>
        </div>
        <RepairBoxExp toggleExpand={() => toggleExpand('repair')} />
        {expandedCategory === 'repair' && renderCategoryDetails('repair')}
      </div>
    </div>
  </div>
  );
}


