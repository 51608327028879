import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/register.css';
import RegisterFormByPatient from './RegisterFormByPatient';
import { BASE_URL } from '../../utils/hostUrl';

const RegisterByPatient = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formValues) => {
    const { name, age, cpf, rg, email, password, phone1, phone2, planCardCode, planName } = formValues;

    const patientFormData = {
      name,
      age,
      cpf,
      rg,
      email,
      password,
      phone1,
      phone2,
      planCardCode,
      planName,
      clinic_id: 1,
      ortho: false,
      vip: false,
      orthoPlanId: 1,
    };

    const addressFormData = {
      postal_code: '',
      street: '',
      city: '',
      country: '',
      address_number: 0,
    };

    setIsLoading(true); // Ativa o spinner ao iniciar a requisição

    try {
      const response = await axios.post(`${BASE_URL}/patients/createByUser`, {
        createPatientUserDto: patientFormData,
        createAddressDto: addressFormData,
      });

      if (response.data.error) {
        alert(response.data.error);
        setIsLoading(false); // Desativa o spinner em caso de erro
        return;
      }

      alert('Seu cadastro foi efetuado com sucesso!');
      const { id } = response.data;

      try {
        const loginResponse = await axios.post(`${BASE_URL}/auth/patient`, {
          doc: email,
          password: password,
        });

        if (loginResponse && loginResponse.data.accessToken) {
          localStorage.setItem('accessTokenPatient', loginResponse.data.accessToken);
          navigate(`/patient-profile/${id}`);
        } else {
          alert('Erro no login automático. Por favor, tente fazer login manualmente.');
        }
      } catch (loginError) {
        alert('Erro inesperado ao realizar o login. Por favor, tente novamente.');
      }
    } catch (error) {
      alert('Erro inesperado ao criar o cadastro. Por favor, tente novamente.');
    } finally {
      setIsLoading(false); // Desativa o spinner ao finalizar a requisição
    }
  };

  return (
    <div className="register">
      {isLoading ? (
        <div className="spinner">
          <div className="loader"></div>
          <p>Carregando...</p>
        </div>
      ) : (
        <RegisterFormByPatient handleSubmit={handleSubmit} />
      )}
    </div>
  );
};

export default RegisterByPatient;
