import React from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import './css/loginPatientForm.css'
import validationSchemaLogin from '../../utils/validationSchemaLogin';
import { BASE_URL } from '../../utils/hostUrl';


const LoginPatientForm = () => {
  const navigate = useNavigate();

  return (
  <div className='login-form-container-ort'>
    <div className='login-form-ort'>
      <Formik
        initialValues={{ doc: '', password: '' }}
        validationSchema={validationSchemaLogin}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const response = await axios.post(`${BASE_URL}/auth/patient`, values);
            if (response.data && response.data.user && response.data.user.id) {
              localStorage.setItem('accessTokenPatient', response.data.accessToken);  
              // Navigate to the patient profile page with the patient ID
              navigate(`/patient-profile/${response.data.user.id}`);
            } else {
              setErrors({ password: 'Erro inesperado. Tente novamente.' });
            }

            if (response.data.error) {
              setErrors({ password: response.data.error });
            }
          } catch (error) {
            if (error.response) {
              setErrors({ password: error.response.data.message || 'Dados de login inválidos' });
            } else {
              setErrors({ password: 'Dados de login inválidos' });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className='login-form-container-sec-ort'>
            <div className='text-box-container-ort'>
              {/* <label
              className='login-form-label-ort' 
              htmlFor="doc"></label> */}
              <ErrorMessage 
              className='error-message-ort'
              name="doc" 
              component="div" />
              <Field
              placeholder='Email ou CPF(números)'
              className="field-form-ort-emailCPF" 
              name="doc" 
              type="text"
              autoComplete = 'username' />
            </div>
            <div className='text-box-container-ort'>
              {/* <label
                className='login-form-label-ort' 
               htmlFor="password"></label> */}
              <ErrorMessage
              className='error-message-ort' 
              name="password" component="div" />
              <Field
              placeholder='Senha'
              className="field-form-ort"  
              name="password" type="password"
              autoComplete = 'current-password' />
            </div>
            <div>
              <button
              className='adm-btns' 
              type="submit" disabled={isSubmitting}>Login</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
  );
};

export default LoginPatientForm;
