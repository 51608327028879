import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderOrtho from '../../components/header/HeaderOrtho';
import ProductsCard from '../../components/productsCard/ProductsCard';
import axios from 'axios'; // Biblioteca para fazer chamadas HTTP
import './css/patient-profile.css'
import PatientProfileCover from '../../components/productsCard/PatientProfileCover';
import PatientCreditsWallet from '../../components/patientProfile/PatientCreditsWallet';
import PatientServicesHistory from '../../components/patientProfile/PatientServicesHistory';
import PatientTransactionsHistory from '../../components/patientProfile/PatientTransactionsHistory';
import RepairCard from '../../components/productsCard/RepairCard';
import QueeContainer from '../../components/quee/queeContainer/QueeContainer';
import PatientDependants from '../../components/patientProfile/PatientDependants';
import { BASE_URL } from '../../utils/hostUrl';
import NoticeBoard from '../../components/patientProfile/NoticeBoard';
import Rules from '../../components/patientProfile/Rules';

const PatientProfile = () => {
  const { id } = useParams(); // Obtém o 'id' dos parâmetros da URL

  // States para armazenar os dados
  const [productsValue, setproductsValue] = useState(null); // Armazena o objeto de produtos
  const [patientInfos, setPatientInfos] = useState(null);
  const [patientHasCredits, setpatientHasCredits] = useState(false);
  const [patientUnlock, setPatientUnlock] = useState([]);
  const [isVirtualStoreOpen, setIsVirtualStoreOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isVirtualQueeOpen, setIsVirtualQueeOpen] = useState(false);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [isMydependantsOpen, setIsMydependantsOpen] = useState(false);
  const patientQuee = true;
   const navigate = useNavigate();
  //const [checkoutInfo, setCheckoutInfo] = useState(null); // Armazena o objeto do paciente, sem o campo password
  // Armazena o objeto do paciente, sem o campo password
  // Função para buscar os produtos

  const verifyToken = async () => {
    const token = localStorage.getItem('accessTokenPatient'); // Recupera o token do localStorage
    try {
      const response = await axios.post(`${BASE_URL}/patients/verify-patient-token`, {
        authorization: token,
      });
     
        if (response.data.patientVerifyId !== Number(id)) {
          navigate(`/auth-odk-patient`)
        }    
  
    } catch (error) {
      console.error('Erro ao verificar o token:', error);
      navigate(`/auth-odk-patient`)
    }
  };



  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/credits/products`);
      if (response.data.length > 0) {
        setproductsValue(response.data[0]); // Salva apenas o primeiro objeto do array
      }
    } catch (error) {
      console.error('Erro ao buscar os produtos:', error);
    }
  };

  // Função para buscar os dados do paciente
  const fetchPatient = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/patients/${id}`);
      const patientData = response.data;
      // Remove o campo password do objeto de paciente
      // const { password, ...patientWithoutPassword } = patientData;
      setPatientInfos(patientData); 
      const credits = hasCredits(patientData.CreditsWallet)
      if (credits) {
        setpatientHasCredits(true)
      }
      
      
      // Salva o objeto sem o campo password
    } catch (error) {
      console.error('Erro ao buscar os dados do paciente:', error);
    }
  };


  const fetchPatientUnlockBenefits = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/benefits/progress/${id}`);
      const patientUnlockData = response.data;
      setPatientUnlock(patientUnlockData); 
      // Salva o objeto sem o campo password
    } catch (error) {
      console.error('Erro ao buscar os dados do paciente:', error);
    }
  };

  // const handleCheckout = () => {
  //   //desativado descomentar a func abaixo para ativar o checkout
  // }

  const handleCheckout = async (productInfo, isPix = false) => {
    
    
    const data = {
      customer: {
        Name: patientInfos.name,
        email: patientInfos.email,
        tax_id: patientInfos.cpf,
      },
      reference_id: id, // id do paciente
      items: [
        {
          reference_id: productInfo.refValue,
          name: productInfo.refId,
          description: productInfo.description,
          quantity: productInfo.quantity,
          unit_amount:productInfo.refId === 'repair' ?
           (Number(productsValue[productInfo.refValue])*100)
           :(Number(productsValue[productInfo.refValue])*100)/productInfo.quantity,
        },
      ],
      customer_modifiable: true,
      payment_methods: isPix ? [ {type: 'PIX'}] : [{type: 'CREDIT_CARD'}],
      payment_methods_configs: [
        {
          type: 'CREDIT_CARD',
          config_options: [
            {
              option: 'INSTALLMENTS_LIMIT',
              value: '12'
            },
            // {
            //   option: 'INTEREST_FREE_INSTALLMENTS',
            //   value: '0'
            // }
          ]
        }
      ],
      additional_amount: isPix ? 0 : ((Number(productsValue[productInfo.refValue])*100)) * 0.05,
    
      notification_urls: ['https://ortho-sys-production.up.railway.app/pagseguro/webhook'],
      payment_notification_urls: [
        'https://ortho-sys-production.up.railway.app/pagseguro/webhook'
      ]
    };
  
    try {
      const response = await fetch(`${BASE_URL}/pagseguro/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      
      const result = await response.json();
     // setCheckoutInfo(result)
      const checkoutUrl = result.links[1].href
      // setTimeout(() => {
      //   // Redireciona para o checkoutUrl
      //   window.location.href = checkoutUrl;
      // }, 1000);

      setTimeout(() => {
        // Abre o checkoutUrl em uma nova aba
        window.open(checkoutUrl, '_blank');
      }, 1000);
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  const hasCredits = (data) => {
    return data.some(entry =>
      entry.standard > 0 ||
      entry.smart > 0 ||
      entry.vip > 0 ||
      entry.special > 0 ||
      entry.specialIn > 0
    );
  }

  // useEffect para fazer o fetch quando o componente for montado
  useEffect(() => {
    verifyToken();
    fetchProducts();
    fetchPatient();
    fetchPatientUnlockBenefits();
    // hasCredits(patientInfos.CreditsWallet);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Reexecuta o efeito sempre que o 'id' mudar



const toggleVirtualStore = () => {
  setIsVirtualStoreOpen(!isVirtualStoreOpen);
};

const toggleHistory = () => {
  setIsHistoryOpen(!isHistoryOpen);
};

const toggleRules = () => {
  setIsRulesOpen(!isRulesOpen);
};


const toggleVirtualQuee = () => {
  setIsVirtualQueeOpen(!isVirtualQueeOpen);
};

const toggleMyDependants = () => {
  setIsMydependantsOpen(!isMydependantsOpen);
};




return (
  <div className='patient-profile-main'>
    <HeaderOrtho />
    {patientInfos && <PatientProfileCover patientName={patientInfos.name} />}
    {productsValue && !patientHasCredits && (
      <ProductsCard productsInfo={productsValue} handleCheckout={handleCheckout} />
    )}

    <NoticeBoard />
  


{patientInfos && patientUnlock.length > 0 && <PatientCreditsWallet patientInfos={patientInfos} patientUnlock={patientUnlock} />}
    {patientInfos && patientHasCredits && (
      <div className='loja-virtual'>
        <div className='loja-virtual-header' onClick={toggleVirtualStore}>
          Loja Virtual {isVirtualStoreOpen ? '▲' : '▼'}
        </div>
        <div className={`loja-virtual-content ${isVirtualStoreOpen ? 'open' : 'closed'}`}>
          <ProductsCard productsInfo={productsValue} handleCheckout={handleCheckout} />
          <RepairCard productsInfo={productsValue} handleCheckout={handleCheckout} />
        </div>
      </div>
    )}

{patientInfos && (
      <div className='virtual-quee-pat'>
        <div className='virtual-quee-pat-header' onClick={toggleVirtualQuee}>
          Fila Virtual {isVirtualQueeOpen ? '▲' : '▼'}
        </div>
        <div className={`virtual-quee-pat-content ${isVirtualQueeOpen ? 'open' : 'closed'}`}>
        <QueeContainer useTitularCred={patientInfos.use_titular_cred} 
        patientDoc={patientInfos.cpf} patientQuee={patientQuee}/>
        </div>
      </div>
    )}


{patientInfos && (
      <div className='dependants'>
        <div className='dependants-header' onClick={toggleMyDependants}>
          Meus Dependentes {isMydependantsOpen ? '▲' : '▼'}
        </div>
        <div className={`dependants-content ${isMydependantsOpen ? 'open' : 'closed'}`}>
        {patientInfos && <PatientDependants beneficiaryId={id}/> }
        </div>
      </div>
    )}
    

    {patientInfos && (
      <div className='history'>
        <div className='history-header' onClick={toggleHistory}>
          Histórico {isHistoryOpen ? '▲' : '▼'}
        </div>
        <div className={`history-content ${isHistoryOpen ? 'open' : 'closed'}`}>
        {patientInfos && <PatientServicesHistory patientInfos={patientInfos} />}
        {patientInfos && <PatientTransactionsHistory patientInfos={patientInfos} />}
        </div>
      </div>
    )}

{patientInfos && (
      <div className='rules'>
        <div className='rules-header' onClick={toggleRules}>
          Regras {isRulesOpen ? '▲' : '▼'}
        </div>
        <div className={`rules-content ${isRulesOpen ? 'open' : 'closed'}`}>
         <Rules />
        </div>
      </div>
    )}

  </div>

  
);


};

export default PatientProfile;
