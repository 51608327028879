import React, { useState } from 'react';

const TreatmentForm = ({ handleSubmit, handleChange, formData, formLoading, error, allocation }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  return (
    <div>
      <h4 
        className='form-treatment-sheet-h4' 
        onClick={toggleFormVisibility} 
        // style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }}
      >
        Adicionar Nova Ficha{' '} 
        <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
          {isFormVisible ? '-' : '+'}
        </span>
      </h4>
      {isFormVisible && (
        <>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form 
            className='form-treatment-sheet' 
            onSubmit={(e) => { e.preventDefault(); handleSubmit(allocation.patient_id); }}
          >
            <label>
              Nome do Tratamento
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                maxLength={64}
              />
            </label>
            <label>
              Dente:
              <input
                type="number"
                name="tooth"
                value={formData.tooth}
                onChange={handleChange}
                min="0"
                max="38"
              />
            </label>
            <label>
              Região:
              <input
                type="text"
                name="region"
                value={formData.region}
                onChange={handleChange}
                maxLength={64}
              />
            </label>
            <label>
              Descrição:
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                maxLength={512}
              />
            </label>
            <label>
              Observação:
              <textarea
                name="obs"
                value={formData.obs}
                onChange={handleChange}
                maxLength={512}
              />
            </label>
            <button type="submit" disabled={formLoading}>
              {formLoading ? 'Salvando...' : 'Adicionar'}
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default TreatmentForm;
