// import React from 'react';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import validationSchema from '../../utils/validationSchema';
// // import '../auth/css/loginPatientForm.css'

// const RegisterFormByPatient = ({ handleSubmit }) => {
//   const initialValues = {
//     name: '',
//     age: '',
//     cpf: '',
//     rg: '',
//     email: '',
//     password: '',
//     phone1: '',
//     phone2: ''
//   };

//   return (
//     <div className='login-form-container-ort'>
//        <div className='login-form-ort'>
//     <Formik
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={(values, { setSubmitting, resetForm }) => {
//         handleSubmit(values);
//         resetForm();
//         setSubmitting(false);
//       }}
//     >
//       {({ isSubmitting }) => (
//         <Form className="register-form-container-sec-ort">
       
//               <div className='text-box-container-ort-cad'>
//               <ErrorMessage name="name" component="div" className="error-message-ort" />
//               <Field className="field-form-ort-cad" type="text" name="name" placeholder="Nome Completo" />
//               </div>
              
//               <div className='text-box-container-ort-cad'>   
//               <ErrorMessage name="cpf" component="div" className="error-message-ort" />
//               <Field className="field-form-ort-cad" type="text" name="cpf" placeholder="CPF" />
//               </div>   

//               <div className='text-box-container-ort-cad'>
//               <ErrorMessage name="email" component="div" className="error-message-ort" />
//               <Field className="field-form-ort-cad" type="email" name="email" placeholder="E-mail" />
//               </div>

//               <div className='text-box-container-ort-cad'>
//               <ErrorMessage name="password" component="div" className="error-message-ort" />
//               <Field className="field-form-ort-cad" type="password" name="password" placeholder="Senha" />
//               </div>

//               <div className='text-box-container-ort-cad'>
//               <Field className="field-form-ort-cad" type="number" name="age" placeholder="Idade" />
//               </div>  

//               <div className='text-box-container-ort-cad'>
//               <Field className="field-form-ort-cad" type="text" name="rg" placeholder="RG" />
//               </div> 

      

//               <div className='text-box-container-ort-cad'>
//               <ErrorMessage name="phone1" component="div" className="error-message-ort" />
//               <Field className="field-form-ort-cad" type="text" name="phone1" placeholder="Telefone 1" />
//               </div>

//               <div className='text-box-container-ort-cad'>
//               <Field className="field-form-ort-cad" type="text" name="phone2" placeholder="Telefone 2" />
//               </div>

//           <div>
//             <button className="adm-btns" type="submit" disabled={isSubmitting}>Cadastrar</button>
//           </div>
//         </Form>
//       )}
//     </Formik>
//       </div>
//     </div>
//   );
  
// };

// export default RegisterFormByPatient;
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import validationSchema from '../../utils/validationSchema';
import '../auth/css/loginPatientForm.css'
import './css/registerByPatForm.css'
import ReactInputMask from 'react-input-mask';

const RegisterFormByPatient = ({ handleSubmit }) => {
  const initialValues = {
    name: '',
    age: '',
    cpf: '',
    // rg: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone1: '',
    confirmPhone:'',
    // phone2: ''
  };

  return (
    <div className='login-form-container-ort'>
       <div className='login-form-ort'>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values);
        resetForm();
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="register-form-container-sec-ort">
       
              <div className='text-box-container-ort-cad'>
              <ErrorMessage name="name" component="div" className="error-message-ort" />
              <Field className="field-form-ort-cad" type="text" name="name" placeholder="Nome completo" />
              </div>
              
              <div className='text-box-container-ort-cad'>   
              <ErrorMessage name="cpf" component="div" className="error-message-ort" />
              <Field className="field-form-ort-cad" type="text" name="cpf" placeholder="CPF" maxlength = '11' />
              </div>   
              
              <Field name="age">
                {({ field }) => (
                  <div className='text-box-container-ort-cad'>
                    <ErrorMessage name="age" component="div" className="error-message-ort" />
                    <ReactInputMask {...field} mask="99/99/9999" className="field-form-ort-cad" placeholder="Data de nascimento" />
                  </div>
                )}
             </Field>

              <div className='text-box-container-ort-cad'>
              <ErrorMessage name="email" component="div" className="error-message-ort" />
              <Field className="field-form-ort-cad" type="email" name="email" placeholder="E-mail" />
              </div>

              <div className='text-box-container-ort-cad'>
              <ErrorMessage name="password" component="div" className="error-message-ort" />
              <Field className="field-form-ort-cad" type="password" name="password" placeholder="Senha" autoComplete="password" />
              </div>

              <div className='text-box-container-ort-cad'>
              <ErrorMessage name="confirmPassword" component="div" className="error-message-ort" />
              <Field className="field-form-ort-cad" type="password" name="confirmPassword" placeholder="Confirmar senha" autoComplete="new password" />
              </div>



              {/* <div className='text-box-container-ort-cad'>
              <Field className="field-form-ort-cad" type="number" name="age" placeholder="Idade" />
              </div>   */}

              {/* <div className='text-box-container-ort-cad'>
              <Field className="field-form-ort-cad" type="text" name="rg" placeholder="RG" />
              </div>  */}

      

              {/* <div className='text-box-container-ort-cad'>
              <ErrorMessage name="phone1" component="div" className="error-message-ort" />
              <Field className="field-form-ort-cad"
              maxLength="11"
              type="text" name="phone1" placeholder="ddd + Telefone" />
              </div> */}

              <Field type="number" name="phone1">
              {({ field }) => (
              <div className='text-box-container-ort-cad'>
                <ErrorMessage name="phone1" component="div" className="error-message-ort" />
                <ReactInputMask {...field} mask="(99)9 9999-9999" className="field-form-ort-cad" placeholder="ddd + Celular" />
              </div>
              )}
              </Field>

              <Field type="number" name="confirmPhone">
              {({ field }) => (
              <div className='text-box-container-ort-cad'>
                <ErrorMessage name="confirmPhone" component="div" className="error-message-ort" />
                <ReactInputMask {...field} mask="(99)9 9999-9999" className="field-form-ort-cad" placeholder="Confirmar celular" />
              </div>
              )}
              </Field>

              {/* <div className='text-box-container-ort-cad'>
              <ErrorMessage name="confirmPhone" component="div" className="error-message-ort" />
              <Field className="field-form-ort-cad" 
              maxLength="11"
              type="text" name="confirmPhone" placeholder="Confirmar telefone" />
              </div> */}

          <div>
            <button className="adm-btns" type="submit" disabled={isSubmitting}>Cadastrar</button>
          </div>
        </Form>
      )}
    </Formik>
      </div>
    </div>
  );
};

export default RegisterFormByPatient;
