import React from 'react';
import './css/peopleCardContainerMob.css';
import './css/peopleCardContainer.css';
import perfilIcon from '../../../assets/icons/perfilIcone.png'
import QueeRemoverBtn from '../queeAdm/QueeRemoverBtn';
import formatName from '../../../utils/formatName';
import QueeLastPositionBtn from '../queeAdm/QueeLastPositionBtn';

function PeopleCardContainer({ peopleList, noRemoverBtn, showDocument }) {
  const personName = localStorage.getItem('patientName');

  // Função para extrair o primeiro nome e o último sobrenome
  // const formatName = (fullName) => {
  //   const nameParts = fullName.split(' ').map(part => 
  //     part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  //   );
  //   if (nameParts.length > 1) {
  //     return `${nameParts[0]} ${nameParts[1]} ${nameParts[nameParts.length - 1]}`;
  //   }
  //   return fullName; // Caso haja apenas um nome, retorna ele mesmo
  // };
  

  return (
    <div className='people-card-container'>
      <div className='fila-title-div'>
      <h2 className='fila-convencional'>Fila Convencional</h2>
      </div>
      {peopleList.length === 0 ? (
        <p></p>
      ) : (
        peopleList.map((person, index) => (
          <div className='people-card' key={person.id}>
            <div className={`people-card ${personName === person.name ? `name-red` : 'person-quee-name'}`}>
              <img className="perfil-icon" src={perfilIcon} alt="icon" />
              <div className='people-place-container'>
                <span className='number-quee'>{index + 1}</span>

                <div className='person-and-document'>
                <p>{formatName(person.name)}</p>
                <p>{showDocument && person.document}</p>  
                </div>

                <div className='person-quee-btns'>
                <QueeLastPositionBtn noRemoverBtn={noRemoverBtn} personId={person.id} />
                <QueeRemoverBtn noRemoverBtn={noRemoverBtn} personId={person.id} />   
                </div>

              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default PeopleCardContainer;
