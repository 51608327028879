import React, { useState, useEffect } from 'react';
import './css/queContainerMob.css';
import './css/queeContainer.css';
import QueeInstructions from './QueeInstructions';
import PeopleCardContainer from './PeopleCardContainer';
import QueeEntry from './QueeEntry'; 
import { io } from 'socket.io-client';
import QueeUperCover from '../queeUpperCover/QueeUperCover';
import PeopleCardContainerVip from './PeopleCardContainerVIP';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../utils/hostUrl';

export default function QueeContainer({ noUperCover, noRemoverBtn, noRoundBtn ,
   patientQuee, patientDoc, dentistQuee, useTitularCred, showDocument }) {
  const [peopleList, setPeopleList] = useState([]);
  const [peopleListVip, setPeopleListVip] = useState([]);
  const [isQueeOpen, setIsQueeOpen] = useState(false);
  const [error, setError] = useState(undefined);

  const { id } = useParams()
  
  useEffect(() => {
    const socket = io(`${BASE_URL}`, {
      transports: ['websocket'], // Apenas WebSocket
    });

        const fetchData = async () => {
      try {
        const [response1, response2] = await Promise.all([
          fetch(`${BASE_URL}/quee`),
          fetch(`${BASE_URL}/quee/status`)
        ]);

        const data1 = await response1.json();
        const data2 = await response2.json();

        // Separar elementos em listas diferentes
        const vipAndSpecial = data1.filter(
          (person) => person.category === 'vip' || person.category === 'special' || person.category === 'specialIn'
        );
        const regularPeople = data1.filter(
          (person) => person.category !== 'vip' && person.category !== 'special' && person.category !== 'specialIn'
        );

        setPeopleList(regularPeople);
        setPeopleListVip(vipAndSpecial);
        setIsQueeOpen(data2.status);
      } catch (error) {
        console.error('Erro ao obter os dados do backend:', error);
      }
    };

    fetchData();
  
    socket.on('connect', () => {
      socket.on('nextPatientCalled', async () => {
        try {
          const response = await fetch(`${BASE_URL}/quee`);
          const data = await response.json();
  
          const vipAndSpecial = data.filter(
            (person) => person.category === 'vip' || person.category === 'special'|| person.category === 'specialIn' || person.category === 'specialIn'
          );
          const regularPeople = data.filter(
            (person) => person.category !== 'vip' && person.category !== 'special' && person.category !== 'specialIn'  && person.category !== 'specialIn'
          );
  
          setPeopleList(regularPeople);
          setPeopleListVip(vipAndSpecial);
        } catch (error) {
          console.error('Erro ao obter os dados do backend:', error);
        }
      });
  
      socket.on('changeStatus', () => {
        setIsQueeOpen((prev) => !prev);
      });
  
      socket.on('addPersonToQuee', async () => {
        try {
          const response = await fetch(`${BASE_URL}/quee`);
          const data = await response.json();
  
          const vipAndSpecial = data.filter(
            (person) => person.category === 'vip' || person.category === 'special' || person.category === 'specialIn'
          );
          const regularPeople = data.filter(
            (person) => person.category !== 'vip' && person.category !== 'special'&& person.category !== 'specialIn'
          );
  
          setPeopleList(regularPeople);
          setPeopleListVip(vipAndSpecial);
        } catch (error) {
          console.error('Erro ao obter os dados do backend:', error);
        }
      });
    });
  
    // Cleanup na desmontagem
    return () => {
      socket.disconnect();
    };
  }, []);
  


  const addPersonToQueue = async (person) => {
    try {
      const response = await fetch(`${BASE_URL}/quee/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(person),
      });
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Erro ao adicionar pessoa à fila.');
      }
      
      if (data.patient) localStorage.setItem('patientName', data.patient);
      
      setError(data.error);
      
    } catch (error) {
      
      setError(error.message);
    
    }
  };

  return (
    <div className='quee-container'>
      {!patientQuee && <QueeUperCover noUperCover={noUperCover} />}
      {!dentistQuee && <QueeInstructions  noRoundBtn={noRoundBtn} />}
      <PeopleCardContainer peopleList={peopleList} noRemoverBtn={noRemoverBtn} showDocument={showDocument} />
      <PeopleCardContainerVip peopleList={peopleListVip} noRemoverBtn={noRemoverBtn} showDocument={showDocument} />
      
      {isQueeOpen && !dentistQuee ? (
        <QueeEntry useTitularCred={useTitularCred} patientDoc={patientDoc}  patientQuee={patientQuee} onAddPerson={addPersonToQueue} error={error} />
      ) : (
        !isQueeOpen && dentistQuee ? <span>No momento a fila está fechada </span>
        : <span>{!dentistQuee && 'No momento a fila está fechada.'}</span>
      )}

    </div>
  );
}
